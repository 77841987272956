import React, { createContext, useState } from 'react';
import { CToast, CToastBody, CToaster, CToastHeader } from '@coreui/react';
import App from 'src/App';
import { lightBlue } from 'src/common/color';

export const addToastContext = createContext(null);

const Toaster = () => {
  const [toasts, setToasts] = useState([]);
  const addToast = ({ target, type, contents, body, header, isAutoHide = true }) => {
    setToasts([
      ...toasts,
      {
        position: 'top-center',
        autohide: isAutoHide ? 5000 : false,
        closeButton: true,
        fade: true,
        target,
        type,
        contents,
        body,
        header,
      },
    ]);
  };

  const toasters = (() => {
    return toasts.reduce((toasters, toast) => {
      toasters[toast.position] = toasters[toast.position] || [];
      toasters[toast.position].push(toast);
      return toasters;
    }, {});
  })();

  return (
    <addToastContext.Provider value={addToast}>
      {Object.keys(toasters).map(toasterKey => (
        <CToaster position={toasterKey} key={'toaster' + toasterKey}>
          {toasters[toasterKey].map((toast, key) => {
            return (
              <CToast key={'toast' + key} show={true} autohide={toast.autohide} fade={toast.fade}>
                <CToastHeader closeButton={toast.closeButton} style={{ backgroundColor: lightBlue, color: 'black' }}>
                  {toast.header ? <div>{toast.header}</div> : <div>{toast.target}</div>}
                </CToastHeader>
                <CToastBody style={{ backgroundColor: '#f8f9fa', color: 'black' }}>
                  {toast.body ? (
                    <div style={{ whiteSpace: 'pre-line' }}>{toast.body}</div>
                  ) : (
                    <div>
                      <b>{toast.contents}</b> {toast.type} 했습니다.
                    </div>
                  )}
                </CToastBody>
              </CToast>
            );
          })}
        </CToaster>
      ))}
      <App />
    </addToastContext.Provider>
  );
};

export default Toaster;
