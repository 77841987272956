import { initialPopupValues } from 'src/views/products/FormValues';

import { API_METHOD, authApiClient } from '../../../api';

// 초기 상태 선언
const initialState = [];

// 액션 타입
const LOAD_POPUPS = 'popups/LOAD_POPUPS';
const RESET_POPUPS = 'popups/RESET_POPUPS';

// 액션 생성함수
const loadPopups = popups => ({ type: LOAD_POPUPS, popups });
export const resetPopups = () => ({ type: RESET_POPUPS, popups: initialState });

export const handleLoadPopups = () => {
  return async dispatch => {
    try {
      const response = await authApiClient(API_METHOD.GET, 'popup', undefined);
      dispatch(loadPopups(response.data.result));
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

// 리듀서 선언
export default function popups(state = initialState, action) {
  switch (action.type) {
    case LOAD_POPUPS: {
      const popups = action.popups.map(popup => {
        let obj = {};

        popup.popup_showing_places.forEach(item => {
          obj[item.showing_place] = true;

          if (item.range === null) return;

          if (item.range === 'all' || item.range === 'basic' || item.range === 'soju') {
            obj[`${item.showing_place}Status`] = item.range;
            return;
          }

          if (typeof JSON.parse(item.range) === 'object') {
            // range가 배열이면 일부 페이지
            obj[`${item.showing_place}Status`] = 'some';
            obj[`${item.showing_place}Array`] = JSON.parse(item.range).join(',');
            return;
          }
        });

        return {
          ...initialPopupValues,
          ...obj,
          id: popup.id,
          title: popup.title,
          image: popup.popup_image_url,
          startDatetime: popup.start_date,
          endDatetime: popup.end_date,
          redirectStatus: popup.redirect_url !== null,
          redirectUrl: popup.redirect_url ?? '',
          status: popup.status === 'active',
          showingPlaces: popup.popup_showing_places,
        };
      });

      return popups;
    }
    case RESET_POPUPS: {
      return action.popups;
    }
    default:
      return state;
  }
}
