import { API_METHOD, authApiClient } from '../../../api';
import { handleLoadBanners } from './banners';

// 액션 타입
export const LOAD_BANNER = 'banner/LOAD_BANNER';
export const SAVE_BANNER = 'banner/SAVE_BANNER';
export const UPDATE_BANNER = 'banner/UPDATE_BANNER';
export const DELETE_BANNER = 'banner/DELETE_BANNER';

// 액션 생성함수
export const loadBanner = banner => ({ type: LOAD_BANNER, banner });
export const saveBanner = banner => ({ type: SAVE_BANNER, banner });
export const updateBanner = banner => ({ type: UPDATE_BANNER, banner });
export const deleteBanner = bannerId => ({ type: DELETE_BANNER, bannerId });

export const handleLoadBanner = bannerId => {
  return async dispatch => {
    try {
      const response = await authApiClient(API_METHOD.GET, `banner/${bannerId}`, undefined);
      dispatch(loadBanner(response.data.result));
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

export const handleSaveBanner = (banner, addToast, resetValues) => {
  return async dispatch => {
    try {
      await authApiClient(API_METHOD.POST, 'banner', banner);
      addToast();
      dispatch(handleLoadBanners());
      resetValues();
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

export const handleUpdateBanner = (banner, addToast, resetValues) => {
  return async dispatch => {
    try {
      await authApiClient(API_METHOD.PATCH, `banner/${banner.id}`, banner);
      addToast();
      dispatch(handleLoadBanners());
      if (resetValues) resetValues();
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

export const handleDeleteBanner = (bannerId, addToast) => {
  return async dispatch => {
    try {
      await authApiClient(API_METHOD.DELETE, `banner/${bannerId}`, undefined);
      addToast();
      dispatch(handleLoadBanners());
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};
// 초기 상태 선언
const initialState = [];

// 리듀서 선언
export default function banner(state = initialState, action) {
  switch (action.type) {
    case LOAD_BANNER: {
      return action.banner;
    }
    default:
      return state;
  }
}
