export const UPDATE_SUBSCRIPTION_SCHEDULE_CATEGORIES = 'subscriptionSchedule/UPDATE_SUBSCRIPTION_SCHEDULE_CATEGORIES';

export const updateSubscriptionScheduleCategories = lists => ({
  type: UPDATE_SUBSCRIPTION_SCHEDULE_CATEGORIES,
  lists,
});

const initialState = [];

export default function subscriptionScheduleCategories(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SUBSCRIPTION_SCHEDULE_CATEGORIES: {
      return action.lists;
    }
    default:
      return state;
  }
}
