import qs from 'qs';

import { API_METHOD, authApiClient } from '../../api';

// 액션 타입
export const LOAD_SUBSCRIPTION_SCHEDULES = 'subscriptionSchedule/LOAD_SUBSCRIPTION_SCHEDULES';
export const SAVE_SUBSCRIPTION_SCHEDULE = 'subscriptionSchedule/SAVE_SUBSCRIPTION_SCHEDULE';
export const UPDATE_SUBSCRIPTION_SCHEDULE = 'subscriptionSchedule/UPDATE_SUBSCRIPTION_SCHEDULE';
export const DELETE_SUBSCRIPTION_SCHEDULE = 'subscriptionSchedule/DELETE_SUBSCRIPTION_SCHEDULE';

// 액션 생성함수
export const loadSubscriptionSchedules = subscriptionSchedules => ({
  type: LOAD_SUBSCRIPTION_SCHEDULES,
  subscriptionSchedules,
});
export const saveSubscriptionSchedule = subscriptionSchedule => ({
  type: SAVE_SUBSCRIPTION_SCHEDULE,
  subscriptionSchedule,
});
export const updateSubscriptionSchedule = subscriptionSchedule => ({
  type: UPDATE_SUBSCRIPTION_SCHEDULE,
  subscriptionSchedule,
});
export const deleteSubscriptionSchedule = subscriptionScheduleId => ({
  type: DELETE_SUBSCRIPTION_SCHEDULE,
  subscriptionScheduleId,
});

export const handleLoadSubscriptionSchedules = query => {
  return async dispatch => {
    try {
      const response = await authApiClient(
        API_METHOD.GET,
        `subscription/schedule?limit=10&${qs.stringify(query)}`,
        undefined
      );
      dispatch(loadSubscriptionSchedules(response.data.result));
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

export const handleSaveSubscriptionSchedule = (subscriptionSchedule, addToast, goToList) => {
  return async () => {
    try {
      await authApiClient(API_METHOD.POST, 'subscription/schedule', subscriptionSchedule);
      addToast();
      goToList();
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

export const handleUpdateSubscriptionSchedule = (subscriptionSchedule, addToast, goToList) => {
  return async () => {
    try {
      await authApiClient(API_METHOD.PATCH, `subscription/schedule/${subscriptionSchedule.id}`, subscriptionSchedule);
      addToast();
      goToList();
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

export const handleDeleteSubscriptionSchedule = (subscriptionScheduleId, addToast) => {
  return async dispatch => {
    try {
      await authApiClient(API_METHOD.DELETE, `subscription/schedule/${subscriptionScheduleId}`, undefined);
      addToast();
      dispatch(handleLoadSubscriptionSchedules());
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

// 초기 상태 선언
const initialState = [];

// 리듀서 선언
export default function subscriptionSchedules(state = initialState, action) {
  switch (action.type) {
    case LOAD_SUBSCRIPTION_SCHEDULES: {
      return action.subscriptionSchedules;
    }
    default:
      return state;
  }
}
