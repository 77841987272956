import axios from 'axios';
import moment from 'moment';
import { logOut } from 'src/utils/logout';

const mode = process.env.REACT_APP_MODE;
const prodUrl = 'https://api.sooldamhwa.com';
const localUrl = 'http://localhost:8080';
const devUrl = 'https://dev.api.damhwa.co';
const stagingUrl = 'https://stg.api.damhwa.co';
const lambdaUrl = 'https://gcsgnt7lfi.execute-api.ap-northeast-2.amazonaws.com/dev';

export const API_TYPE = {
  ROOT: 'root',
  LAMBDA: 'lambda',
  BULK_ORDER: 'bulkOrder',
};

export function getAPIClient(type) {
  let apiUrl;
  let apiClient;
  if (mode == 'DEV') {
    apiUrl = devUrl;
  } else if (mode === 'STG') {
    apiUrl = stagingUrl;
  } else if (mode === 'PROD') {
    apiUrl = prodUrl;
  } else if (mode === 'LOCAL') {
    apiUrl = localUrl;
  }

  if (type == API_TYPE.ROOT) {
    apiClient = axios.create({
      baseURL: apiUrl,
      timeout: 200000,
    });
  } else if (type == API_TYPE.LAMBDA) {
    apiClient = axios.create({
      baseURL: lambdaUrl,
      timeout: 200000,
    });
  } else if (type == API_TYPE.BULK_ORDER) {
    // 서버 요청에 따라 구독 결제완료 관리의 구독 주문 일괄 수령확인 호출 시 500초로 지정
    apiClient = axios.create({
      baseURL: `${apiUrl}/admin`,
      timeout: 500000,
    });
  } else {
    apiClient = axios.create({
      baseURL: `${apiUrl}/admin`,
      timeout: 200000,
    });
  }
  return apiClient;
}

export const API_METHOD = {
  GET: 'get',
  POST: 'post',
  PATCH: 'patch',
  DELETE: 'delete',
  POST_FILE: 'post_file',
  LAMBDA_EXCEL_GET: 'get_lambda',
};

export async function authApiClient(method, url, body, apiOption, type = '') {
  const apiClient = getAPIClient(type);
  const token = localStorage.getItem('datacenter-access-token');
  const headers = { Authorization: `Bearer ${token}` };
  let response;

  try {
    switch (method) {
      case API_METHOD.GET:
        response = await apiClient.get(url, {
          headers,
        });
        break;
      case API_METHOD.LAMBDA_EXCEL_GET:
        response = await apiClient.get(url, {
          headers,
          responseType: 'blob',
        });
        break;
      case API_METHOD.POST:
        response = await apiClient.post(url, body, {
          headers,
        });
        break;
      case API_METHOD.POST_FILE:
        response = await apiClient.post(url, body, apiOption);
        break;
      case API_METHOD.PATCH:
        response = await apiClient.patch(url, body, {
          headers,
        });
        break;
      case API_METHOD.DELETE:
        response = await apiClient.delete(url, {
          headers,
        });
        break;
    }

    return response;
  } catch (error) {
    await errorNotify(error);

    if (error.response?.status === 401) {
      const reqUrl = url.includes('&access_token')
        ? `${url.split('=')[0]}=${localStorage.getItem('datacenter-access-token')}`
        : url;
      return await authApiClient(method, reqUrl, body, apiOption, type);
    }
  }
}

export const blank = [undefined, null, 'null', [], '[]', '', '"null"'];

export function substituteBlank(input) {
  if (blank.includes(input)) return '';
  else return input;
}

export const getYYYYMMDD = date => {
  const year = date.getFullYear();
  const month = ('0' + (1 + date.getMonth())).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);

  return year + '-' + month + '-' + day;
};

export const getFullDate = date => {
  if (!date) return '';

  const year = date.getFullYear();
  const month = ('0' + (1 + date.getMonth())).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  let yoil = date.getDay();
  switch (yoil) {
    case 0: {
      yoil = '일';
      break;
    }
    case 1: {
      yoil = '월';
      break;
    }
    case 2: {
      yoil = '화';
      break;
    }
    case 3: {
      yoil = '수';
      break;
    }
    case 4: {
      yoil = '목';
      break;
    }
    case 5: {
      yoil = '금';
      break;
    }
    case 6: {
      yoil = '토';
      break;
    }
  }
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);

  return `${year}-${month}-${day}(${yoil}) ${hours}:${minutes}`;
};

export const getDay = date => {
  const year = date.getFullYear();
  const month = ('0' + (1 + date.getMonth())).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);

  return `${year}-${month}-${day}`;
};

export const getAge = date => {
  const birthDate = moment(date);
  const koreanAge = moment().diff(birthDate.format('YYYYMMDD'), 'years') + 1;
  return koreanAge;
};

export const errorNotify = async e => {
  if (e.response) {
    const {
      response: {
        data: { code, message },
        status,
      },
    } = e;

    if (code === 3092) {
      window.location.replace(`/order/list/${merchantId}`);
    }

    // refresh token expired
    if (code === 2000) {
      logOut();
      throw new Error(message);
    }

    if (e.response.data instanceof Blob) {
      var reader = new FileReader();

      reader.onload = function () {
        var result = JSON.parse(this.result); // blob이 JSON 형태인 경우
        alert(JSON.stringify(result.message, null, 2)); // 결과를 경고창으로 표시
      };

      reader.readAsText(e.response.data); // blob을 텍스트로 읽기
    }

    try {
      // access token expired
      if (status === 401) {
        const datacenterRefreshToken = localStorage.getItem('datacenter-refresh-token');
        const {
          data: { code, message, result },
        } = await authApiClient(
          API_METHOD.POST,
          'auth/admin/token',
          {
            refresh_token: datacenterRefreshToken,
          },
          undefined,
          API_TYPE.ROOT
        );
        if (code !== 1) {
          logOut();
          throw new Error(message);
        }

        const { access_token } = result;
        localStorage.setItem('datacenter-access-token', access_token);
      } else {
        if (code !== 1) {
          throw new Error(message);
        }
      }
    } catch (error) {
      throw new Error(error);
    }
  } else {
    throw new Error(e);
  }
};
