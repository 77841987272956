import { foodValues } from 'src/views/products/FormValues';

import { API_METHOD, authApiClient, blank } from '../../api';
import { handleLoadFoods } from './foods';

// 액션 타입
export const LOAD_FOOD = 'food/LOAD_FOOD';
export const SAVE_FOOD = 'food/SAVE_FOOD';
export const UPDATE_FOOD = 'food/UPDATE_FOOD';
export const DELETE_FOOD = 'food/DELETE_FOOD';

// 액션 생성함수
export const loadFood = food => ({ type: LOAD_FOOD, food });
export const saveFood = food => ({ type: SAVE_FOOD, food });
export const updateFood = food => ({ type: UPDATE_FOOD, food });
export const deleteFood = foodId => ({ type: DELETE_FOOD, foodId });

export const handleFood = props => {
  return async dispatch => {
    try {
      switch (props.type) {
        case LOAD_FOOD: {
          const response = await authApiClient(API_METHOD.GET, `food/${props.foodId}`, undefined);
          dispatch(loadFood(response.data.result));
          break;
        }
        case SAVE_FOOD: {
          const response = await authApiClient(API_METHOD.POST, 'food', props.food, undefined);
          if (response.data.code === 1) {
            props.addToast();
          }
          dispatch(handleLoadFoods());
          break;
        }
        case UPDATE_FOOD: {
          const response = await authApiClient(API_METHOD.PATCH, `food/${props.food.id}`, props.food);
          if (response.data.code === 1) {
            props.addToast();
          }
          dispatch(handleLoadFoods());
          break;
        }
        case DELETE_FOOD: {
          await authApiClient(API_METHOD.DELETE, `food/${props.foodId}`, undefined);
          await authApiClient(API_METHOD.GET, 'food', undefined);
          dispatch(handleLoadFoods());
          break;
        }
        default: {
          break;
        }
      }
    } catch (e) {
      alert(e.message);
    }
  };
};

// 리듀서 선언
export default function food(state = foodValues, action) {
  switch (action.type) {
    case LOAD_FOOD: {
      if (action.food.id) {
        if (blank.includes(action.food.image_url) == false) {
          let image_url_array = [action.food.image_url];
          delete action.food.image_url;
          action.food.image_url = image_url_array;
        } else {
          action.food.image_url = [];
        }
      }
      return action.food;
    }
    default:
      return state;
  }
}
