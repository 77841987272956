import qs from 'qs';

import { API_METHOD, authApiClient } from '../../api';

// 액션 타입
const LOAD_ORDERS = 'orders/LOAD_ORDERS';
const LOAD_CS_ORDERS = 'orders/LOAD_CS_ORDERS';
const TOGGLE_ORDER = 'orders/LOAD_ORDER';

// 액션 생성함수
const loadOrders = orders => ({ type: LOAD_ORDERS, orders });
const loadCsOrders = orders => ({ type: LOAD_CS_ORDERS, orders });
const toggleOrder = (orders, id, checked) => ({ type: TOGGLE_ORDER, orders, id, checked });

export const handleLoadOrders = (query, setLoading) => {
  return async dispatch => {
    try {
      if (setLoading) {
        setLoading(true);
      }

      const response = await authApiClient(API_METHOD.GET, `order?limit=10&${qs.stringify(query)}`, undefined);
      dispatch(loadOrders(response.data.result));
      if (setLoading) {
        setLoading(false);
      }
    } catch (e) {
      alert(e.message);
      if (setLoading) {
        setLoading(false);
      }
      return;
    }
  };
};

export const handleLoadCsOrders = query => {
  return async dispatch => {
    try {
      const response = await authApiClient(API_METHOD.GET, `order-cs?limit=10&${qs.stringify(query)}`, undefined);
      dispatch(loadCsOrders(response.data.result));
    } catch (e) {
      alert(e.message);
    }
  };
};

// order pay -> ready 변경
export const handleOrderSetDelivery = (values, query) => {
  return async dispatch => {
    try {
      await authApiClient(API_METHOD.PATCH, 'order/delivery', values);
      alert(`${values.delivery_company} ${values.delivery_code} 입력 성공, 배송 상태를 확인해주세요.`);
      dispatch(handleLoadOrders(query));
    } catch (e) {
      alert(e.message);
      dispatch(handleLoadOrders(query));
      return;
    }
  };
};

export const handleOrderReceiveConfirm = (values, query, id) => {
  return async dispatch => {
    try {
      await authApiClient(API_METHOD.PATCH, 'order/delivery/confirm', values);
      alert(`${id} 수령 확인 변경 성공, 배송 상태를 확인해주세요.`);
      dispatch(handleLoadOrders(query));
    } catch (e) {
      alert(e.message);
      dispatch(handleLoadOrders(query));
      return;
    }
  };
};

export const handleOrderSetDeliveryWait = (values, query, id) => {
  return async dispatch => {
    try {
      await authApiClient(API_METHOD.PATCH, 'order/delivery/delivery-wait', values);
      alert(`${id} 출고 대기중 변경 성공, 배송 상태를 확인해주세요.`);
      dispatch(handleLoadOrders(query));
    } catch (e) {
      alert(e.message);
      dispatch(handleLoadOrders(query));
      return;
    }
  };
};

export const handleToggle = (orders, id, checked) => {
  return async dispatch => {
    dispatch(toggleOrder(orders, id, checked));
  };
};

export const handleSdhChargeSubmit = (values, addToast, query) => {
  return async dispatch => {
    try {
      await authApiClient(API_METHOD.PATCH, 'order-cs/sdh-charge', values);
      addToast();
      dispatch(handleLoadCsOrders(query));
    } catch (e) {
      alert(e.message);
      dispatch(handleLoadCsOrders(query));
      return;
    }
  };
};

// 초기 상태 선언
const initialState = [];

// 리듀서 선언
export default function orders(state = initialState, action) {
  switch (action.type) {
    case LOAD_ORDERS: {
      // make object flat for CDatatable
      if (action.orders.count != 0) {
        // 둘 중 하나라도 falsy가 있으면 ''로 한다.
        action.orders.rows = action.orders.rows
          .filter(item => item.status !== 'pending' && item.status !== 'adjust')
          .map(item => ({
            ...item,
            user_name: item?.user?.name || '',
            phone: item?.user?.phone || '',
            maker_name: item?.maker?.name || '',
            merchant_id: item?.payment?.merchant_id || '',
            receiver_name: item?.receiver_address?.receiver_name || '',
            delivery_company: item?.invoice?.delivery_company || '',
            delivery_code: item?.invoice?.delivery_code || '',
          }));
      }
      return action.orders;
    }
    case LOAD_CS_ORDERS: {
      // make object flat for CDatatable
      if (action.orders.count != 0) {
        // 둘 중 하나라도 falsy가 있으면 ''로 한다.
        action.orders.rows = action.orders.rows
          .filter(item => item.status !== 'pending' && item.status !== 'adjust')
          .map(item => ({
            ...item,
            user_name: (item.user && item.user.name && item.user.name) || '',
            phone: (item.user && item.user.phone && item.user.phone) || '',
            maker_name: item.order?.maker?.name,
            merchant_id: (item.payment && item.payment.merchant_id && item.payment.merchant_id) || '',
            receiver_name: (item.payment && item.payment.receiver_name && item.payment.receiver_name) || '',
            receiver_base_address: (item.payment.receiver_base_address && item.payment.receiver_base_address) || '',
            paid_time: (item.payment.paid_time && item.payment.paid_time) || '',
            receiver_detail_address:
              (item.payment.receiver_detail_address && item.payment.receiver_detail_address) || '',
            receiver_zipcode: (item.payment.receiver_zipcode && item.payment.receiver_zipcode) || '',
            delivery_comment: (item.payment.delivery_comment && item.payment.delivery_comment) || '',
            pay_price: (item.order && item.order.pay_price && item.order.pay_price) || '',
            product_name: item.order?.product_name,
            product_option_name: item.order?.product_option_name,
          }));
      }
      return action.orders;
    }
    case TOGGLE_ORDER: {
      if (action.id == 'all')
        // toggle All
        action.orders.rows = action.orders.rows.map(item => ({ ...item, checked: action.checked }));
      else
        action.orders.rows = action.orders.rows.map(
          item => (item.id == action.id && { ...item, checked: action.checked }) || item
        );
      return action.orders;
    }
    default:
      return state;
  }
}
