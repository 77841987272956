import qs from 'qs';
import { setSubmitting } from 'src/redux/modules/webpage/loading';

import { API_METHOD, authApiClient } from '../../api';

export const LOAD_USERS = 'LOAD_USERS';
export const CHECK_USER = 'CHECK_USER';
export const RESET_USERS = 'RESET_USERS';

export const loadUsers = users => ({
  type: LOAD_USERS,
  users,
});
const checkUser = (users, id, checked) => ({ type: CHECK_USER, users, id, checked });
const resetUsers = () => ({ type: RESET_USERS });

export const handleLoadUsers = query => {
  return async dispatch => {
    try {
      dispatch(setSubmitting(true));
      const response = await authApiClient(API_METHOD.GET, `user?limit=10&${qs.stringify(query)}`, undefined);
      dispatch(loadUsers(response.data.result));
    } catch (e) {
      alert(e.message);
    } finally {
      dispatch(setSubmitting(false));
    }
  };
};

export const handleCheck = (users, id, checked) => {
  return async dispatch => {
    dispatch(checkUser(users, id, checked));
  };
};

export const handleSavePoint = (values, addToast, query) => {
  return async dispatch => {
    try {
      dispatch(setSubmitting(true));
      await authApiClient(API_METHOD.PATCH, 'user/point', values);
      addToast();
    } catch (e) {
      alert(e.message);
    } finally {
      dispatch(setSubmitting(false));
    }

    dispatch(handleLoadUsers(query));
  };
};

export const handleResetUsers = () => {
  return async dispatch => {
    dispatch(resetUsers());
  };
};

const users = (state = [], action) => {
  switch (action.type) {
    case LOAD_USERS: {
      return action.users;
    }
    case CHECK_USER: {
      if (action.id == 'all')
        // toggle All
        action.users.rows = action.users.rows.map(item => ({ ...item, checked: action.checked }));
      else
        action.users.rows = action.users.rows.map(
          item => (item.id == action.id && { ...item, checked: action.checked }) || item
        );
      return action.users;
    }
    case RESET_USERS: {
      return { count: 0, rows: [] };
    }
    default:
      return state;
  }
};

export default users;
