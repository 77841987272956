import moment from 'moment';
import { setSubmitting } from 'src/redux/modules/webpage/loading';

import { API_METHOD, authApiClient } from '../../../api';
import { handleLoadCurations, updateCurationStatus } from './curations';

// 액션 타입
export const LOAD_CURATION = 'curation/LOAD_CURATION';
export const SAVE_CURATION = 'curation/SAVE_CURATION';
export const UPDATE_CURATION = 'curation/UPDATE_CURATION';
export const DELETE_CURATION = 'curation/DELETE_CURATION';

// 액션 생성함수
export const loadCuration = curation => ({ type: LOAD_CURATION, curation });
export const saveCuration = curation => ({ type: SAVE_CURATION, curation });
export const updateCuration = curation => ({ type: UPDATE_CURATION, curation });
export const deleteCuration = curationId => ({ type: DELETE_CURATION, curationId });

export const handleLoadCuration = curationId => {
  return async dispatch => {
    try {
      const response = await authApiClient(
        API_METHOD.GET,
        `curation-display/${curationId}?list_type=datacenter`,
        undefined
      );
      dispatch(loadCuration(response.data.result));
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

export const handleSaveCuration = (curation, addToast) => {
  return async () => {
    try {
      await authApiClient(API_METHOD.POST, 'curation-display', curation);
      addToast();
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

export const handleUpdateCuration = (curation, addToast) => {
  return async () => {
    try {
      await authApiClient(API_METHOD.PATCH, `curation-display/${curation.id}`, curation);
      addToast();
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

export const handleUpdateCurationStatus = (state, curations) => async dispatch => {
  try {
    dispatch(setSubmitting(true));
    await authApiClient(API_METHOD.PATCH, `curation-display/${state.id}`, state);
    dispatch(updateCurationStatus(state, curations));
  } catch (e) {
    alert(e.message);
    return;
  } finally {
    dispatch(setSubmitting(false));
  }
};

export const handleDeleteCuration = curationId => {
  return async dispatch => {
    try {
      await authApiClient(API_METHOD.DELETE, `curation-display/${curationId}`, undefined);
      dispatch(handleLoadCurations());
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};
// 초기 상태 선언
const initialState = [];

// 리듀서 선언
export default function curation(state = initialState, action) {
  switch (action.type) {
    case LOAD_CURATION: {
      // falsy 처리
      action.curation.icon_img_url = (action.curation.icon_img_url && [action.curation.icon_img_url]) || [];
      action.curation.banner_image_url = (action.curation.banner_image_url && [action.curation.banner_image_url]) || [];
      action.curation.mobile_banner_image_url =
        (action.curation.mobile_banner_image_url && [action.curation.mobile_banner_image_url]) || [];
      action.curation.order_no = (action.curation.order_no && String(action.curation.order_no)) || '';
      action.curation.show_no = (action.curation.show_no && String(action.curation.show_no)) || '';
      action.curation.detail_image_url = (action.curation.detail_image_url && [action.curation.detail_image_url]) || [];
      action.curation.mobile_detail_image_url =
        (action.curation.mobile_detail_image_url && [action.curation.mobile_detail_image_url]) || [];

      action.curation.coupon_image_url = (action.curation.coupon_image_url && [action.curation.coupon_image_url]) || [];
      action.curation.mobile_coupon_image_url =
        (action.curation.mobile_coupon_image_url && [action.curation.mobile_coupon_image_url]) || [];
      action.curation.search_home_image_url =
        (action.curation.search_home_image_url && [action.curation.search_home_image_url]) || [];

      if (action.curation.products) {
        // 홈페이지와 같은 순서로 보여주기 위한 정렬
        action.curation.products.sort((a, b) => {
          return a.curation_display_products.id < b.curation_display_products.id
            ? -1
            : a.curation_display_products.id > b.curation_display_products.id
            ? 1
            : 0;
        });
        action.curation.products_id = String(action.curation.products.map(item => item.id));
      } else {
        action.curation.products_id = '';
      }

      if (action.curation.start_date) {
        action.curation.start_date = moment(action.curation.start_date).format('YYYY-MM-DDTHH:mm');
      } else {
        action.curation.start_date = '';
      }

      if (action.curation.end_date) {
        action.curation.end_date = moment(action.curation.end_date).format('YYYY-MM-DDTHH:mm');
      } else {
        action.curation.end_date = '';
      }

      return action.curation;
    }
    default:
      return state;
  }
}
