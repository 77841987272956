import qs from 'qs';

import { API_METHOD, authApiClient } from '../../api';

// 액션 타입
const LOAD_FOODS = 'foods/LOAD_FOODS';

// 액션 생성함수
export const loadFoods = foods => ({ type: LOAD_FOODS, foods });

export const handleLoadFoods = query => {
  return async dispatch => {
    try {
      const response = await authApiClient(API_METHOD.GET, `food?${qs.stringify(query)}`, undefined);
      dispatch(loadFoods(response.data.result));
    } catch (e) {
      alert(e.message);
    }
  };
};
// 초기 상태 선언
const initialState = [];

// 리듀서 선언
export default function foods(state = initialState, action) {
  switch (action.type) {
    case LOAD_FOODS:
      return action.foods;
    default:
      return state;
  }
}
