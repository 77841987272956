import { getSubscriptionQuery } from 'src/redux/modules/subscription/common';

import { API_METHOD, authApiClient } from '../../api';

// 액션 타입
const LOAD_SUBSCRIPTION_STATUS = 'subscription/LOAD_SUBSCRIPTION_STATUS';

// 액션 생성함수
const loadSubscriptionStatus = subscriptionStatus => ({ type: LOAD_SUBSCRIPTION_STATUS, subscriptionStatus });

export const handleLoadSubscriptionStatus = (query, setLoading) => {
  return async dispatch => {
    try {
      setLoading(true);

      const response = await authApiClient(
        API_METHOD.GET,
        `subscription?limit=10${getSubscriptionQuery(query)}`,
        undefined
      );

      dispatch(loadSubscriptionStatus(response.data.result));
    } catch (e) {
      alert(e.message);
    } finally {
      setLoading(false);
    }
  };
};

export const cancelSubscription = (id, returnCoupon, addToast, query, setLoading) => {
  return async dispatch => {
    try {
      await authApiClient(API_METHOD.PATCH, `subscription/${id}/cancel/`, {
        reason: '관리자 구독취소',
        return_coupon: returnCoupon,
      });
      addToast();
      dispatch(handleLoadSubscriptionStatus(query, setLoading));
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

export const changeAddressPermanently = (subscriptionId, addressId, address, addToast, query, setLoading) => {
  return async dispatch => {
    try {
      await authApiClient(API_METHOD.PATCH, `subscription/${subscriptionId}/address/${addressId}`, address);
      addToast();
      dispatch(handleLoadSubscriptionStatus(query, setLoading));
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

export const handleChangeAmount = (values, subscriptionId, addToast, query, setLoading) => {
  return async dispatch => {
    try {
      await authApiClient(API_METHOD.PATCH, `subscription/${subscriptionId}`, values);
      addToast();
      dispatch(handleLoadSubscriptionStatus(query, setLoading));
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

// 초기 상태 선언
const initialState = [];

// 리듀서 선언
export default function subscriptionStatus(state = initialState, action) {
  switch (action.type) {
    case LOAD_SUBSCRIPTION_STATUS: {
      action.subscriptionStatus.rows.map(item => {
        for (let key in item) {
          if (item[key] !== 0 && !item[key]) item[key] = '';
        }
        const isSubscriptionOrders = item.subscription_orders.length > 0;

        if (item.user) {
          item.name = item.user.name;
          item.phone = item.user.phone;
          item.email = item.user.email;
        }

        if (isSubscriptionOrders) {
          item.address_list = item.subscription_orders.map(({ id, address_id, address }) => {
            const {
              base_address = '',
              detail_address = '',
              directions = '',
              phone_number = '',
              receiver_name = '',
              subscription_orders = '',
              zipcode = '',
            } = address || {};

            return {
              order_id: id,
              address_id,
              base_address,
              detail_address,
              directions,
              phone_number,
              receiver_name,
              subscription_orders,
              zipcode,
            };
          });

          item.main_receiver_name = item.address_list[0].receiver_name;
          item.main_phone_number = item.address_list[0].phone_number;
          item.main_zipcode = item.address_list[0].zipcode;
          item.main_receiver_address = `${item.address_list[0].base_address} ${item.address_list[0].detail_address}`;

          item.coupon_discount_total_price = item.subscription_orders
            .map(item => item.coupon_discount_price)
            .reduce((acc, curr) => acc + curr, 0);
          item.couponName = item.subscription_orders[0].coupon?.name || '';
          item.discount_months = item.subscription_orders[0].coupon?.discount_months || '';
          item.remaining_discount_months = item.subscription_orders[0].coupon?.remaining_discount_months || '';

          item.product_name = item.subscription_orders[0].product_name;
        }
      });

      return action.subscriptionStatus;
    }
    default:
      return state;
  }
}
