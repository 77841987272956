import { API_METHOD, authApiClient } from '../../api';
import { loadMakers } from './makers';

export const LOAD_MAKER = 'LOAD_MAKER';
export const SAVE_MAKER = 'SAVE_MAKER';
export const UPDATE_MAKER = 'UPDATE_MAKER';

export const saveMaker = maker => ({
  type: SAVE_MAKER,
  maker,
});

export const loadMaker = maker => ({
  type: LOAD_MAKER,
  maker,
});

export const updateMaker = maker => ({
  type: UPDATE_MAKER,
  maker,
});

export const handleSaveMaker = (maker, addToast) => async () => {
  try {
    await authApiClient(API_METHOD.POST, 'maker', maker);
    addToast();
  } catch (e) {
    alert(e.message);
  }
};

export const handleUpdateMaker = (maker, addToast) => async () => {
  try {
    await authApiClient(API_METHOD.PATCH, `maker/${maker.id}`, maker);
    addToast();
  } catch (e) {
    alert(e.message);
  }
};

export const handleDeleteMaker = makerId => async dispatch => {
  try {
    await authApiClient(API_METHOD.DELETE, `maker/${makerId}`, undefined);
    const response = await authApiClient(API_METHOD.GET, 'maker', undefined);
    dispatch(loadMakers(response.data.result.rows));
  } catch (e) {
    alert(e.message);
  }
};

export const handleLoadMaker = makerId => async dispatch => {
  try {
    const response = await authApiClient(API_METHOD.GET, `maker/${makerId}`, undefined);
    dispatch(loadMaker(response.data.result));
  } catch (e) {
    alert(e.message);
  }
};

export const handleLoadMakers = () => {
  return async dispatch => {
    try {
      const response = await authApiClient(API_METHOD.GET, 'maker', undefined);
      dispatch(loadMakers(response.data.result.rows));
    } catch (e) {
      alert(e.message);
    }
  };
};

const maker = (state = {}, action) => {
  switch (action.type) {
    case LOAD_MAKER: {
      action.maker.delivery_company = (action.maker.delivery_company && action.maker.delivery_company) || '';
      action.maker.account_number = (action.maker.account_number && action.maker.account_number) || '';
      action.maker.account_holder = (action.maker.account_holder && action.maker.account_holder) || '';
      action.maker.bank_name = (action.maker.bank_name && action.maker.bank_name) || '';
      action.maker.note = (action.maker.note && action.maker.note) || '';
      return action.maker;
    }
    default:
      return state;
  }
};

export default maker;
