import qs from 'qs';
import { setSubmitting } from 'src/redux/modules/webpage/loading';

import { API_METHOD, authApiClient } from '../../api';

export const LOAD_MAKERS = 'LOAD_MAKERS';
export const UPDATE_MAKER_STATUS = 'UPDATE_MAKER_STATUS';

export const loadMakers = makers => ({
  type: LOAD_MAKERS,
  makers,
});

export const updateMakerStatus = (state, makers) => ({
  type: UPDATE_MAKER_STATUS,
  maker: state,
  makers,
});

export const handleLoadMakers = () => {
  return async dispatch => {
    try {
      const response = await authApiClient(API_METHOD.GET, 'maker', undefined);
      dispatch(loadMakers(response.data.result.rows));
    } catch (e) {
      alert(e.message);
    }
  };
};

export const handleSearchMakers = query => {
  return async dispatch => {
    try {
      const response = await authApiClient(API_METHOD.GET, `maker?${qs.stringify(query)}`, undefined);
      dispatch(loadMakers(response.data.result.rows));
    } catch (e) {
      alert(e.message);
    }
  };
};

export const handleUpdateMakerStatus = (state, makers) => async dispatch => {
  try {
    dispatch(setSubmitting(true));
    await authApiClient(API_METHOD.PATCH, `maker/${state.id}`, state);
    dispatch(updateMakerStatus(state, makers));
  } catch (e) {
    alert(e.message);
    return;
  } finally {
    dispatch(setSubmitting(false));
  }
};

const makers = (state = [], action) => {
  switch (action.type) {
    case LOAD_MAKERS:
      return action.makers;
    case UPDATE_MAKER_STATUS: {
      action.makers = action.makers.map(item =>
        item.id == action.maker.id ? { ...item, status: action.maker.status } : item
      );
      return action.makers;
    }
    default:
      return state;
  }
};

export default makers;
