import { combineReducers } from 'redux';

import previousUrl from '../modules/common/previousUrl';
import coupon from '../modules/coupon/coupon';
import coupons from '../modules/coupon/coupons';
import maker from '../modules/maker/maker';
import makerPolicy from '../modules/maker/makerPolicy';
import makers from '../modules/maker/makers';
import autoForm from '../modules/order/autoForm';
import merchantDetail from '../modules/order/merchantDetail';
import orders from '../modules/order/orders';
import previewForm from '../modules/order/previewForm';
import refundForm from '../modules/order/refundForm';
import food from '../modules/product/food';
import foods from '../modules/product/foods';
import product from '../modules/product/product';
import productInfo from '../modules/product/productInfo';
import productOption from '../modules/product/productOption';
import productOptions from '../modules/product/productOptions';
import products from '../modules/product/products';
import scent from '../modules/product/scent';
import scents from '../modules/product/scents';
import review from '../modules/review/review';
import reviews from '../modules/review/reviews';
import subscriptionHint from '../modules/subscription/subscriptionHint';
import subscriptionHints from '../modules/subscription/subscriptionHints';
import subscriptionOrders from '../modules/subscription/subscriptionOrders';
import subscriptionProducts from '../modules/subscription/subscriptionProducts';
import subscriptionSchedule from '../modules/subscription/subscriptionSchedule';
import subscriptionScheduleCategories from '../modules/subscription/subscriptionScheduleCategories';
import subscriptionSchedules from '../modules/subscription/subscriptionSchedules';
import subscriptionStatus from '../modules/subscription/subscriptionStatus';
import pointHistory from '../modules/user/pointHistory';
import user from '../modules/user/user';
import users from '../modules/user/users';
import banner from '../modules/webpage/banner/banner';
import banners from '../modules/webpage/banner/banners';
import curation from '../modules/webpage/curation/curation';
import curations from '../modules/webpage/curation/curations';
// import shops from './shops'
import loading from '../modules/webpage/loading';
import popups from '../modules/webpage/popup/popups';
import viewChanges from './viewChange';
// import nail from './nail'
// import tags from './tags'
// import suggestions from './suggestions'
// import stats from './stats'
// import searched_nails  from './searched_nails'
// import categories from './categories'
// import ui_state from './ui_state'

export default combineReducers({
  maker,
  makers,
  makerPolicy,
  viewChanges,
  product,
  products,
  productOption,
  productOptions,
  productInfo,
  previousUrl,
  user,
  users,
  foods,
  food,
  scents,
  scent,
  orders,
  curations,
  curation,
  coupon,
  coupons,
  subscriptionOrders,
  subscriptionSchedule,
  subscriptionScheduleCategories,
  subscriptionSchedules,
  subscriptionProducts,
  subscriptionStatus,
  subscriptionHint,
  subscriptionHints,
  pointHistory,
  review,
  reviews,
  banner,
  banners,
  merchantDetail,
  refundForm,
  autoForm,
  previewForm,
  popups,
  loading,
  // nail,
  // tags,
  // suggestions,
  // stats,
  // searched_nails,
  // categories,
  // ui_state
});
