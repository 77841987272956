import qs from 'qs';

import { API_METHOD, authApiClient } from '../../../api';

// 액션 타입
const LOAD_CURATIONS = 'curations/LOAD_CURATIONS';
const UPDATE_CURATION_STATUS = 'curations/UPDATE_CURATION_STATUS';

// 액션 생성함수
const loadCurations = curations => ({ type: LOAD_CURATIONS, curations });
export const updateCurationStatus = (state, curations) => ({
  type: UPDATE_CURATION_STATUS,
  curation: state,
  curations,
});

export const handleLoadCurations = query => {
  return async dispatch => {
    try {
      if (query) delete query.page;
      const response = await authApiClient(API_METHOD.GET, `curation-display?${qs.stringify(query)}`, undefined);
      dispatch(loadCurations(response.data.result));
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};
// 초기 상태 선언
const initialState = [];

// 리듀서 선언
export default function curations(state = initialState, action) {
  switch (action.type) {
    case LOAD_CURATIONS: {
      return action.curations;
    }
    case UPDATE_CURATION_STATUS:
      return action.curations.map(item =>
        item.id == action.curation.id ? { ...item, status: action.curation.status } : item
      );
    default:
      return state;
  }
}
