import React, { useState } from 'react';
import { cilUser } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from '@coreui/react';
import PropTypes from 'prop-types';
import sooldamhwaMainLogo from 'src/image/sooldamhwa-main-logo.png';
import { getAPIClient } from 'src/redux/api';

const apiClient = getAPIClient('root');

async function loginUser(credentials, setToken) {
  try {
    const response = await apiClient.post('auth/admin/login', credentials);
    const { access_token, refresh_token, admin_auths } = response.data.result;
    setToken(access_token, refresh_token);
    localStorage.setItem('datacenter-admin-auths', JSON.stringify(admin_auths));
  } catch (e) {
    if (e.response) {
      alert(e.response.data.message);
    }
  }
}

const Login = ({ setToken }) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    await loginUser({ email, password }, setToken);
  };

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="text-white py-5 d-md-down-none" style={{ width: '44%' }}>
                <CCardBody
                  className="text-center"
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <img id="main-logo" src={sooldamhwaMainLogo} alt="술담화" />
                </CCardBody>
              </CCard>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit}>
                    <h1>관리도구</h1>
                    <p className="text-muted">이 세상의 술자리를 다채롭게 만들자!</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon icon={cilUser} width={16} height={16} />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="text"
                        placeholder="아이디"
                        autoComplete="username"
                        onChange={e => setEmail(e.target.value)}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon icon={cilUser} width={16} height={16} />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="password"
                        placeholder="비밀번호"
                        autoComplete="current-password"
                        onChange={e => setPassword(e.target.value)}
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs="6">
                        <CButton type="submit" color="primary" className="px-4">
                          로그인
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
