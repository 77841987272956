import { API_METHOD, authApiClient } from '../../api';
import { getSubscriptionQuery } from './common';

// 액션 타입
const LOAD_SUBSCRIPTION_ORDERS = 'subscriptionOrders/LOAD_SUBSCRIPTION_ORDERS';
const LOAD_COMPLETE_SUBSCRIPTION_ORDERS = 'subscriptionOrders/LOAD_COMPLETE_SUBSCRIPTION_ORDERS';
const TOGGLE_SUBSCRIPTION_ORDER = 'subscriptionOrders/TOGGLE_SUBSCRIPTION_ORDER';
const RESET_ORDERS = 'subscriptionOrders/RESET_ORDERS';

// 액션 생성함수
export const loadSubscriptionOrders = subscriptionOrders => ({ type: LOAD_SUBSCRIPTION_ORDERS, subscriptionOrders });
export const loadCompleteSubscriptionOrders = subscriptionOrders => ({
  type: LOAD_COMPLETE_SUBSCRIPTION_ORDERS,
  subscriptionOrders,
});
const toggleSubscriptionOrder = (subscriptionOrders, id, checked) => ({
  type: TOGGLE_SUBSCRIPTION_ORDER,
  subscriptionOrders,
  id,
  checked,
});
export const resetOrders = () => ({
  type: RESET_ORDERS,
  subscriptionOrders: {
    rows: [],
    count: 0,
  },
});

export const handleLoadSubscriptionOrders = (query, setLoading) => {
  return async dispatch => {
    try {
      setLoading(true);
      const response = await authApiClient(
        API_METHOD.GET,
        `subscription/order?limit=10${getSubscriptionQuery(query)}`,
        undefined
      );
      dispatch(loadSubscriptionOrders(response.data.result));
      setLoading(false);
    } catch (e) {
      alert(e.message);
      setLoading(false);
      return;
    }
  };
};

export const handleLoadCompleteSubscriptionOrders = (query, setLoading) => {
  return async dispatch => {
    try {
      setLoading(true);
      const response = await authApiClient(
        API_METHOD.GET,
        `subscription/order/success?limit=10${getSubscriptionQuery(query)}`,
        undefined
      );
      dispatch(loadCompleteSubscriptionOrders(response.data.result));
      setLoading(false);
    } catch (e) {
      alert(e.message);
      setLoading(false);
      return;
    }
  };
};

export const cancelSubscriptionPayment = (id, returnCoupon, addToast, query, setLoading) => {
  return async dispatch => {
    try {
      await authApiClient(API_METHOD.PATCH, `subscription/order/${id}/refund`, {
        reason: '관리자 결제취소',
        return_coupon: returnCoupon,
      });
      addToast();
      dispatch(handleLoadCompleteSubscriptionOrders(query, setLoading));
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

export const changeAddressTemporarily = (subscriptionOrder, addToast, query, setLoading) => {
  return async dispatch => {
    try {
      await authApiClient(API_METHOD.PATCH, `subscription/order/${subscriptionOrder.id}/address`, subscriptionOrder);
      addToast();
      dispatch(handleLoadSubscriptionOrders(query, setLoading));
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

export const handlePaymentSync = (addToast, query, setLoading, subscriptionPlanId) => {
  return async dispatch => {
    try {
      await authApiClient(API_METHOD.PATCH, `subscription/sync/${subscriptionPlanId}`, undefined);
      addToast();
      dispatch(handleLoadSubscriptionOrders(query, setLoading));
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

export const handleToggle = (subscriptionOrders, id, checked) => {
  return async dispatch => {
    dispatch(toggleSubscriptionOrder(subscriptionOrders, id, checked));
  };
};

export const handleResetOrders = () => {
  return dispatch => {
    dispatch(resetOrders());
  };
};

export const handleReceiveConfirm = (subscription_schedule_id, addToast, query, setLoading, setChecking) => {
  let response;
  return async dispatch => {
    try {
      setChecking(true);
      response = await authApiClient(API_METHOD.PATCH, 'subscription/order/confirm', subscription_schedule_id);
      addToast();
      dispatch(handleLoadSubscriptionOrders(query, setLoading));
    } catch (e) {
      alert(e.message);
      return;
    }

    if (response.data.result.fail_arr.length) alert(`실패 리스트 : ${response.data.result.fail_arr}`);
    setChecking(false);
  };
};

// 초기 상태 선언
const initialState = [];

// 리듀서 선언
export default function subscriptionOrders(state = initialState, action) {
  switch (action.type) {
    case LOAD_SUBSCRIPTION_ORDERS: {
      action.subscriptionOrders.rows.map(item => {
        for (let key in item) {
          if (item[key] !== 0 && !item[key]) item[key] = '';
        }
        item.pay_status = (item.subscription_payment.status && item.subscription_payment.status) || '';
        item.name = (item.user.name && item.user.name) || '';
        item.phone = (item.user.phone && item.user.phone) || '';
        item.email = (item.user.email && item.user.email) || '';
        item.initial_order = (item.initial_order && item.initial_order) || '';
        item.subscription_schedule_name = (item.subscription_schedule.name && item.subscription_schedule.name) || '';
        item.merchant_id =
          (item.subscription_payment.merchant_id && item.subscription_payment.merchant_id) || item.merchant_id;
        item.payment_created_at = (item.subscription_payment.created_at && item.subscription_payment.created_at) || '';
        item.future_payment_created_at =
          (item.subscription_schedule.billing_date && item.subscription_schedule.billing_date) || '';

        if (item.coupon) {
          item.couponName = item.coupon.name;
        }
      });
      return action.subscriptionOrders;
    }
    case LOAD_COMPLETE_SUBSCRIPTION_ORDERS: {
      action.subscriptionOrders.rows.map(item => {
        for (let key in item) {
          if (item[key] !== 0 && !item[key]) item[key] = '';
        }
        item.pay_status = (item.subscription_payment.status && item.subscription_payment.status) || '';
        item.name = (item.user.name && item.user.name) || '';
        item.phone = (item.user.phone && item.user.phone) || '';
        item.email = (item.user.email && item.user.email) || '';
        item.initial_order = (item.initial_order && item.initial_order) || '';
        item.subscription_schedule_name = (item.subscription_schedule.name && item.subscription_schedule.name) || '';
        item.merchant_id =
          (item.subscription_payment.merchant_id && item.subscription_payment.merchant_id) || item.merchant_id;
        item.payment_created_at = (item.subscription_payment.created_at && item.subscription_payment.created_at) || '';
        item.future_payment_created_at =
          (item.subscription_schedule.billing_date && item.subscription_schedule.billing_date) || '';
        item.payment_status = (item.subscription_payment.status && item.subscription_payment.status) || '';

        if (item.coupon) {
          item.couponName = item.coupon.name;
        }
      });
      return action.subscriptionOrders;
    }
    case RESET_ORDERS: {
      return action.subscriptionOrders;
    }
    default:
      return state;
  }
}
