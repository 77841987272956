import { API_METHOD, authApiClient } from '../../api';
import { handleLoadSubscriptionHints } from './subscriptionHints';

// 액션 타입
export const LOAD_SUBSCRIPTION_HINT = 'subscriptionHint/LOAD_SUBSCRIPTION_HINT';
export const SAVE_SUBSCRIPTION_HINT = 'subscriptionHint/SAVE_SUBSCRIPTION_HINT';
export const UPDATE_SUBSCRIPTION_HINT = 'subscriptionHint/UPDATE_SUBSCRIPTION_HINT';
export const DELETE_SUBSCRIPTION_HINT = 'subscriptionHint/DELETE_SUBSCRIPTION_HINT';

// 액션 생성함수
export const loadSubscriptionHint = subscriptionHint => ({ type: LOAD_SUBSCRIPTION_HINT, subscriptionHint });
export const saveSubscriptopHint = subscriptionHint => ({ type: SAVE_SUBSCRIPTION_HINT, subscriptionHint });

export const handleLoadSubscriptionHint = subscriptionHintId => {
  return async dispatch => {
    try {
      const response = await authApiClient(API_METHOD.GET, `subscriptionHint/${subscriptionHintId}`, undefined);
      dispatch(loadSubscriptionHint(response.data.result));
    } catch (e) {
      alert(e.message);
    }
  };
};

export const handleSaveSubscriptionHint = (subscriptionHint, addToast) => {
  return async dispatch => {
    try {
      await authApiClient(API_METHOD.POST, 'subscriptionHint', subscriptionHint);
      addToast();
      dispatch(handleLoadSubscriptionHints());
    } catch (e) {
      alert(e.message);
    }
  };
};

export const handleUpdateSubscriptionHint = (subscriptionHint, addToast, query) => {
  return async dispatch => {
    try {
      const id = subscriptionHint.id;
      delete subscriptionHint.id;
      await authApiClient(API_METHOD.PATCH, `subscriptionHint/${id}`, subscriptionHint);
      addToast(subscriptionHint);
      dispatch(handleLoadSubscriptionHints(query));
    } catch (e) {
      alert(e.message);
    }
  };
};

// 초기 상태 선언
const initialState = [];

// 리듀서 선언
export default function subscriptionHint(state = initialState, action) {
  switch (action.type) {
    case LOAD_SUBSCRIPTION_HINT: {
      if (action.subscriptionHint.id) {
        if (action.subscriptionHint.img_url) {
          action.subscriptionHint.img_url = [action.subscriptionHint.img_url];
        } else {
          action.subscriptionHint.img_url = [];
        }
      }
      return action.subscriptionHint;
    }
    default:
      return state;
  }
}
