import { userValues } from 'src/views/products/FormValues';

import { API_METHOD, authApiClient } from '../../api';

export const LOAD_USER = 'LOAD_USER';
export const UPDATE_USER = 'UPDATE_USER';

export const loadUser = user => ({
  type: LOAD_USER,
  user,
});

export const updateUser = user => ({
  type: UPDATE_USER,
  user,
});

export const handleUpdateUser = user => async dispatch => {
  try {
    const response = await authApiClient(API_METHOD.PATCH, `user/${user.id}`, user);
    dispatch(updateUser(response.data.result));
  } catch (e) {
    alert(e.message);
  }
};

export const handleLoadUser = userId => async dispatch => {
  try {
    const response = await authApiClient(API_METHOD.GET, `user/${userId}`, undefined);
    if (response.data.result == undefined) {
      dispatch(loadUser(userValues));
    } else {
      dispatch(loadUser(response.data.result));
    }
  } catch (e) {
    alert(e.message);
  }
};

const user = (state = {}, action) => {
  switch (action.type) {
    case LOAD_USER:
      return action.user;
    case UPDATE_USER:
      return action.user;
    default:
      return state;
  }
};

export default user;
