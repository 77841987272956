import qs from 'qs';

import { API_METHOD, authApiClient } from '../../api';
// 액션 타입
const LOAD_COUPONS = 'coupons/LOAD_COUPONS';
const UPDATE_COUPON_STATUS = 'coupons/UPDATE_COUPON_STATUS';

// 액션 생성함수
const loadCoupons = coupons => ({ type: LOAD_COUPONS, coupons });
export const updateCouponStatus = (state, coupons) => ({
  type: UPDATE_COUPON_STATUS,
  coupon: state,
  coupons,
});

export const handleLoadCoupons = query => {
  return async dispatch => {
    try {
      const response = await authApiClient(API_METHOD.GET, `stamp?${qs.stringify(query)}`, undefined);
      dispatch(loadCoupons(response.data.result));
    } catch (e) {
      alert(e.message);
    }
  };
};
// 초기 상태 선언
const initialState = [];

// 리듀서 선언
export default function coupons(state = initialState, action) {
  switch (action.type) {
    case LOAD_COUPONS: {
      // handle undefined or null value
      action.coupons.rows.map(item => {
        for (let key in item) {
          if (item[key] !== 0 && !item[key]) item[key] = '';
        }
      });
      action.coupons.rows.map(item => {
        item.min_price = item.condition_obj && JSON.parse(item.condition_obj).min_price;
      });

      return action.coupons;
    }
    case UPDATE_COUPON_STATUS:
      return action.coupons.map(item =>
        item.id == action.coupon.id ? { ...item, status: action.coupon.status } : item
      );
    default:
      return state;
  }
}
