// 초기 상태 선언
const initialState = {
  payment_id: '',
  order_arr: [],
};

// 액션 타입
const SET_PREVIEW_FORM = 'previewForm/SET_PREVIEW_FORM';
const CHANGE_PREVIEW_FORM = 'previewForm/CHANGE_PREVIEW_FORM';
const RESET_PREVIEW_FORM = 'previewForm/RESET_PREVIEW_FORM';

// 액션 생성함수
export const setPreviewForm = data => ({
  type: SET_PREVIEW_FORM,
  data,
});

export const changePreviewForm = data => ({
  type: CHANGE_PREVIEW_FORM,
  data,
});

export const resetPreviewForm = () => ({
  type: RESET_PREVIEW_FORM,
  previewForm: initialState,
});

// 리듀서 선언
export default function previewForm(state = initialState, action) {
  switch (action.type) {
    case SET_PREVIEW_FORM: {
      return action.data;
    }
    case CHANGE_PREVIEW_FORM: {
      const result = {
        ...state,
        order_arr: state.order_arr.map(item => ({
          ...item,
          ...action.data.filter(data => data.order_id === item.order_id)[0],
        })),
      };
      return result;
    }
    case RESET_PREVIEW_FORM: {
      return action.previewForm;
    }
    default:
      return state;
  }
}
