export const DISCOUNT_CONDITION_RANGES = {
  CURATION: '0',
  ALL: '1',
  CATEGORY: '2',
  PRODUCT_UNIT: '3',
  OPTION_UNIT: '4',
};

export const EXCLUDE_DISCOUNT_CONDITION_RANGES = {
  NONE: '0',
  CURATION: '1',
  CATEGORY: '2',
  PRODUCT_UNIT: '3',
  OPTION_UNIT: '4',
  DISCOUNTED_PRODUCT: '5',
};
