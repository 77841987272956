import moment from 'moment';

import { API_METHOD, authApiClient } from '../../api';

// 액션 타입
export const LOAD_SUBSCRIPTION_SCHEDULE = 'subscriptionSchedule/LOAD_SUBSCRIPTION_SCHEDULE';
export const SAVE_SUBSCRIPTION_SCHEDULE = 'subscriptionSchedule/SAVE_SUBSCRIPTION_SCHEDULE';
export const UPDATE_SUBSCRIPTION_SCHEDULE = 'subscriptionSchedule/UPDATE_SUBSCRIPTION_SCHEDULE';
export const DELETE_SUBSCRIPTION_SCHEDULE = 'subscriptionSchedule/DELETE_SUBSCRIPTION_SCHEDULE';

// 액션 생성함수
export const loadSubscriptionSchedule = subscriptionSchedule => ({
  type: LOAD_SUBSCRIPTION_SCHEDULE,
  subscriptionSchedule,
});

export const handleLoadSubscriptionSchedule = subscriptionScheduleId => {
  return async dispatch => {
    try {
      const response = await authApiClient(
        API_METHOD.GET,
        `subscription/schedule/${subscriptionScheduleId}`,
        undefined
      );
      dispatch(loadSubscriptionSchedule(response.data.result));
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

// 초기 상태 선언
const initialState = [];

// 리듀서 선언
export default function subscriptionSchedules(state = initialState, action) {
  switch (action.type) {
    case LOAD_SUBSCRIPTION_SCHEDULE: {
      action.subscriptionSchedule.stock = String(action.subscriptionSchedule.stock);
      action.subscriptionSchedule.product_option_id = String(action.subscriptionSchedule.product_option_id);

      action.subscriptionSchedule.billing_date = moment(action.subscriptionSchedule.billing_date).format(
        'YYYY-MM-DDTHH:mm:ss.SSS'
      );
      action.subscriptionSchedule.shipping_date = moment(action.subscriptionSchedule.shipping_date).format(
        'YYYY-MM-DDTHH:mm:ss.SSS'
      );

      action.subscriptionSchedule.concept_image = action.subscriptionSchedule.concept_image
        ? [action.subscriptionSchedule.concept_image]
        : [];
      action.subscriptionSchedule.vertical_concept_image = action.subscriptionSchedule.vertical_concept_image
        ? [action.subscriptionSchedule.vertical_concept_image]
        : [];
      action.subscriptionSchedule.detail_images = action.subscriptionSchedule.detail_images
        ? action.subscriptionSchedule.detail_images.split(',')
        : [];
      action.subscriptionSchedule.mobile_detail_images = action.subscriptionSchedule.mobile_detail_images
        ? action.subscriptionSchedule.mobile_detail_images.split(',')
        : [];
      action.subscriptionSchedule.box_content_image = action.subscriptionSchedule.box_content_image
        ? [action.subscriptionSchedule.box_content_image]
        : [];

      return action.subscriptionSchedule;
    }
    default:
      return state;
  }
}
