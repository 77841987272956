import {
  cilApplicationsSettings,
  cilCalculator,
  cilCalendar,
  cilCart,
  cilDollar,
  cilDrink,
  cilDrinkAlcohol,
  cilFactory,
  cilHistory,
  cilHome,
  cilImage,
  cilList,
  cilLockLocked,
  cilMoon,
  cilNotes,
  cilPencil,
  cilPeople,
  cilPowerStandby,
  cilReload,
  cilSearch,
  cilSmile,
  cilSortDescending,
  cilSpeech,
  cilSpeedometer,
  cilStar,
  cilStarHalf,
  cilSun,
  cilThumbUp,
  cilUser,
  // cilViewlist,
  cilXCircle,
} from '@coreui/icons';

import { logo } from './logo';
import { logoNegative } from './logo-negative';
import { sygnet } from './sygnet';

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
  },
  {
    cilApplicationsSettings,
    cilSpeedometer,
    cilSun,
    cilMoon,
    cilDrink,
    cilXCircle,
    // cilViewlist,
    cilPencil,
    cilPeople,
    cilUser,
    cilList,
    cilFactory,
    cilCart,
    cilThumbUp,
    cilSmile,
    cilLockLocked,
    cilDollar,
    cilPowerStandby,
    cilReload,
    cilHistory,
    cilHome,
    cilCalculator,
    cilCalendar,
    cilDrinkAlcohol,
    cilNotes,
    cilImage,
    cilStar,
    cilStarHalf,
    cilSortDescending,
    cilSearch,
    cilSpeech,
  }
);
