// 액션 타입
export const SET_LOADING = 'loading/SET_LOADING';

// 액션 생성함수
export const setSubmitting = isSubmitting => ({ type: SET_LOADING, isSubmitting });

// 초기 상태 선언
const initialState = false;

// 리듀서 선언
export default function loading(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING: {
      return action.isSubmitting;
    }
    default:
      return state;
  }
}
