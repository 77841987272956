// core ui color
export const primary = '#321fdb';
export const secondary = '#ced2d8';
export const success = '#2eb85c';
export const danger = '#e55353';
export const warning = '#f9b115';
export const info = '#3399ff';
export const light = '#ebedef';
export const dark = '#636f83';

// custom
export const lightBlue = '#a5d8ff';
export const lightGrey = '#dee2e6';
export const orange = '#ffa94d';
