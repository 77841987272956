import qs from 'qs';

import { API_METHOD, authApiClient } from '../../../api';

// 초기 상태 선언
const initialState = [];

// 액션 타입
const LOAD_BANNERS = 'banners/LOAD_BANNERS';
const RESET_BANNERS = 'banners/RESET_BANNERS';

// 액션 생성함수
const loadBanners = banners => ({ type: LOAD_BANNERS, banners });
export const resetBanners = () => ({ type: RESET_BANNERS, banners: initialState });

export const handleLoadBanners = query => {
  return async dispatch => {
    try {
      const response = await authApiClient(API_METHOD.GET, `banner?${qs.stringify(query)}`, undefined);
      dispatch(loadBanners(response.data.result));
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

// 리듀서 선언
export default function banners(state = initialState, action) {
  switch (action.type) {
    case LOAD_BANNERS: {
      action.banners.map(item => (item.url = (item.url && item.url) || ''));
      return action.banners;
    }
    case RESET_BANNERS: {
      return action.banners;
    }
    default:
      return state;
  }
}
