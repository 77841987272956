import { API_METHOD, authApiClient } from '../../api';

// 액션 타입
const LOAD_MERCHANT_DETAIL = 'merchantDetail/LOAD_MERCHANT_DETAIL';
const CHECK_ORDER = 'merchantDetail/CHECK_ORDER';

// 액션 생성함수
const loadMerchantDetail = merchantDetail => ({ type: LOAD_MERCHANT_DETAIL, merchantDetail });
const checkOrder = (paymentData, idObject, checked) => ({ type: CHECK_ORDER, paymentData, idObject, checked });

export const handleLoadMerchantDetail = merchantId => {
  const body = {
    merchant_id: merchantId,
  };
  return async dispatch => {
    try {
      const response = await authApiClient(API_METHOD.POST, 'order/payment', body);
      dispatch(loadMerchantDetail(response.data.result));
    } catch (e) {
      alert(e.message);
    }
  };
};

export const handleCheck = (paymentData, idObject, checked) => {
  return async dispatch => {
    dispatch(checkOrder(paymentData, idObject, checked));
  };
};

// 초기 상태 선언
const initialState = [];

// 리듀서 선언
export default function merchantDetail(state = initialState, action) {
  switch (action.type) {
    case LOAD_MERCHANT_DETAIL: {
      action.merchantDetail?.paymentCsInfos?.unshift(action.merchantDetail?.paymentOrderedInfo);
      action.merchantDetail = action.merchantDetail?.paymentCsInfos;

      action.merchantDetail = action.merchantDetail?.map(item => {
        if (item.order_cses) {
          return {
            ...item,
            order_cses: item.order_cses.map(item => ({
              ...item,
              maker_name: item.order.maker.name,
              product_name: item.order.product_name,
              product_option_name: item.order.product_option_name,
            })),
          };
        } else {
          return { ...item };
        }
      });

      return action.merchantDetail;
    }
    case CHECK_ORDER: {
      let result;
      if (action.idObject.name === 'receiverAddressId') {
        // 체크박스 전체 클릭
        // paymentData에서 array receiver_address 요소 중 id가 receiverAddressId 인 것을 찾아
        // 내부 orders 배열 내부 모든 요소에 checked: action.checked 를 추가한다
        result = action.paymentData.receiver_addresses.map(address =>
          address.id === action.idObject.id
            ? { ...address, orders: address.orders.map(order => ({ ...order, checked: action.checked })) }
            : address
        );
      } else {
        // paymentData에서 array receiver_address 의 orders 배열 중에
        // id가 orderId인 요소를 찾아 checked: action.checked로 추가한다
        result = action.paymentData.receiver_addresses.map(address =>
          address.orders.find(order => order.id === action.idObject.id)
            ? {
                ...address,
                orders: address.orders.map(order =>
                  order.id === action.idObject.id ? { ...order, checked: action.checked } : order
                ),
              }
            : address
        );
      }
      state = state.map(item => (item.status === 'pay' ? { ...item, receiver_addresses: result } : item));

      return state;
    }
    default:
      return state;
  }
}
