import qs from 'qs';
import { setSubmitting } from 'src/redux/modules/webpage/loading';

import 'core-js/es/array';

import { API_METHOD, authApiClient } from '../../api';

export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
export const UPDATE_PRODUCT_STATUS = 'UPDATE_PRODUCT_STATUS';

export const loadProducts = products => ({
  type: LOAD_PRODUCTS,
  products,
});

export const updateProductStatus = (product, products) => ({
  type: UPDATE_PRODUCT_STATUS,
  product,
  products,
});

export const handleLoadProducts = query => {
  return async dispatch => {
    try {
      dispatch(setSubmitting(true));
      const response = await authApiClient(
        API_METHOD.GET,
        `${Object.keys(query).length ? `product?limit=10&${qs.stringify(query)}` : 'product?limit=10'}`,
        undefined
      );
      dispatch(loadProducts(response.data.result));
    } catch (e) {
      alert(e.message);
    } finally {
      dispatch(setSubmitting(false));
    }
  };
};

const products = (state = [], action) => {
  switch (action.type) {
    case LOAD_PRODUCTS: {
      action.products.rows = action.products.rows.map(item => ({
        ...item,
        maker_name: item.maker.name,
        sool_category_name: (item.sool && item.sool.sool_category.name) || '',
      }));
      return action.products;
    }
    case UPDATE_PRODUCT_STATUS: {
      action.products.rows = action.products.map(item =>
        item.id == action.product.id ? { ...item, status: action.product.status } : item
      );
      return action.products;
    }
    default:
      return state;
  }
};

export default products;
