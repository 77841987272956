// 액션 타입
const SET_REFUND_FORM = 'refundForm/SET_REFUND_FORM';
const CHANGE_REFUND_FORM = 'refundForm/CHANGE_REFUND_FORM';

// 액션 생성함수
export const setRefundForm = data => ({
  type: SET_REFUND_FORM,
  data,
});

export const changeRefundForm = data => ({
  type: CHANGE_REFUND_FORM,
  data,
});

// 초기 상태 선언
const initialState = {
  payment_id: '',
  order_arr: [],
};

// 리듀서 선언
export default function refundForm(state = initialState, action) {
  switch (action.type) {
    case SET_REFUND_FORM: {
      return action.data;
    }
    case CHANGE_REFUND_FORM: {
      const result = {
        ...state,
        order_arr: state.order_arr.map(item =>
          item.order_id === action.data.order_id ? { ...item, ...action.data } : item
        ),
      };
      return result;
    }
    default:
      return state;
  }
}
