import { API_METHOD, authApiClient } from '../../api';

// 액션 타입
export const LOAD_SUBSCRIPTION_PRODUCTS = 'subscriptionProduct/LOAD_SUBSCRIPTION_PRODUCTS';
export const SAVE_SUBSCRIPTION_PRODUCT = 'subscriptionProduct/SAVE_SUBSCRIPTION_PRODUCT';
export const UPDATE_SUBSCRIPTION_PRODUCT = 'subscriptionProduct/UPDATE_SUBSCRIPTION_PRODUCT';
export const DELETE_SUBSCRIPTION_PRODUCT = 'subscriptionProduct/DELETE_SUBSCRIPTION_PRODUCT';

// 액션 생성함수
export const loadSubscriptionProducts = subscriptionProducts => ({
  type: LOAD_SUBSCRIPTION_PRODUCTS,
  subscriptionProducts,
});
export const saveSubscriptionProduct = subscriptionProduct => ({
  type: SAVE_SUBSCRIPTION_PRODUCT,
  subscriptionProduct,
});
export const updateSubscriptionProduct = subscriptionProduct => ({
  type: UPDATE_SUBSCRIPTION_PRODUCT,
  subscriptionProduct,
});
export const deleteSubscriptionProduct = subscriptionProductId => ({
  type: DELETE_SUBSCRIPTION_PRODUCT,
  subscriptionProductId,
});

export const handleLoadSubscriptionProducts = () => {
  return async dispatch => {
    try {
      const response = await authApiClient(API_METHOD.GET, 'subscription/plan', undefined);
      dispatch(loadSubscriptionProducts(response.data.result));
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

export const handleSaveSubscriptionProduct = (subscriptionProduct, addToast) => {
  return async () => {
    try {
      await authApiClient(API_METHOD.POST, 'subscription/plan', subscriptionProduct);
      addToast();
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

export const handleUpdateSubscriptionProduct = (subscriptionProduct, addToast) => {
  return async () => {
    try {
      await authApiClient(API_METHOD.PATCH, `subscription/plan/${subscriptionProduct.id}`, subscriptionProduct);
      addToast();
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

export const handleDeleteSubscriptionProduct = subscriptionProductId => {
  return async dispatch => {
    try {
      await authApiClient(API_METHOD.DELETE, `subscription/plan/${subscriptionProductId}`, undefined);
      dispatch(handleLoadSubscriptionProducts());
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};
// 초기 상태 선언
const initialState = [];

// 리듀서 선언
export default function subscriptionProducts(state = initialState, action) {
  switch (action.type) {
    case LOAD_SUBSCRIPTION_PRODUCTS: {
      action.subscriptionProducts.rows.map(item => {
        for (let key in item) {
          if (item[key] !== 0 && !item[key]) item[key] = '';
        }
      });
      return action.subscriptionProducts;
    }
    default:
      return state;
  }
}
