import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('datacenter-access-token');
    const userToken = tokenString;
    return userToken;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (datacenterAccessToken, datacenterRefreshToken) => {
    localStorage.setItem('datacenter-access-token', datacenterAccessToken);
    localStorage.setItem('datacenter-refresh-token', datacenterRefreshToken);
    setToken(datacenterAccessToken);
  };

  return {
    token,
    setToken: saveToken,
  };
}
