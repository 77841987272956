import { scentValues } from 'src/views/products/FormValues';

import { API_METHOD, authApiClient } from '../../api';
import { handleLoadScents } from './scents';

// 액션 타입
export const LOAD_SCENT = 'scent/LOAD_SCENT';
export const SAVE_SCENT = 'scent/SAVE_SCENT';
export const UPDATE_SCENT = 'scent/UPDATE_SCENT';
export const DELETE_SCENT = 'scent/DELETE_SCENT';

// 액션 생성함수
export const loadScent = scent => ({ type: LOAD_SCENT, scent });
export const saveScent = scent => ({ type: SAVE_SCENT, scent });
export const updatSscent = scent => ({ type: UPDATE_SCENT, scent });
export const deleteScent = scentId => ({ type: DELETE_SCENT, scentId });

export const handleScent = props => {
  return async dispatch => {
    try {
      switch (props.type) {
        case LOAD_SCENT: {
          const response = await authApiClient(API_METHOD.GET, `scent/${props.scentId}`, undefined);
          dispatch(loadScent(response.data.result));
          break;
        }
        case SAVE_SCENT: {
          await authApiClient(API_METHOD.POST, 'scent', props.scent, undefined);
          props.addToast();
          dispatch(handleLoadScents());
          break;
        }
        case UPDATE_SCENT: {
          await authApiClient(API_METHOD.PATCH, `scent/${props.scent.id}`, props.scent);
          props.addToast();
          dispatch(handleLoadScents());
          break;
        }
        case DELETE_SCENT: {
          await authApiClient(API_METHOD.DELETE, `scent/${props.scentId}`, undefined);
          await authApiClient(API_METHOD.GET, 'scent', undefined);
          dispatch(handleLoadScents());
          break;
        }
        default: {
          break;
        }
      }
    } catch (e) {
      alert(e.message);
    }
  };
};

// 리듀서 선언
export default function scent(state = scentValues, action) {
  switch (action.type) {
    case LOAD_SCENT: {
      if (action.scent.id) {
        if (action.scent.image_url) {
          let image_url_array = [action.scent.image_url];
          delete action.scent.image_url;
          action.scent.image_url = image_url_array;
        } else {
          action.scent.image_url = [];
        }
      }
      return action.scent;
    }
    default:
      return state;
  }
}
