import moment from 'moment';
import { setSubmitting } from 'src/redux/modules/webpage/loading';
import { couponValues } from 'src/views/products/FormValues';

import { DISCOUNT_CONDITION_RANGES, EXCLUDE_DISCOUNT_CONDITION_RANGES } from '../../../views/coupon/constants.js';
import { API_METHOD, authApiClient } from '../../api';
import { handleLoadCoupons, updateCouponStatus } from './coupons';

// 액션 타입
export const LOAD_COUPON = 'coupon/LOAD_COUPON';
export const SAVE_COUPON = 'coupon/SAVE_COUPON';
export const UPDATE_COUPON = 'coupon/UPDATE_COUPON';
export const DELETE_COUPON = 'coupon/DELETE_COUPON';

// 액션 생성함수
export const loadCoupon = coupon => ({ type: LOAD_COUPON, coupon });
export const saveCoupon = coupon => ({ type: SAVE_COUPON, coupon });
export const updateCoupon = coupon => ({ type: UPDATE_COUPON, coupon });
export const deleteCoupon = couponId => ({ type: DELETE_COUPON, couponId });

export const handleLoadCoupon = couponId => {
  return async dispatch => {
    try {
      const response = await authApiClient(API_METHOD.GET, `stamp/${couponId}`, undefined);
      dispatch(loadCoupon(response.data.result));
    } catch (e) {
      alert(e.message);
    }
  };
};

export const handleSaveCoupon = (coupon, addToast) => {
  return async () => {
    try {
      await authApiClient(API_METHOD.POST, 'stamp', coupon);
      addToast();
    } catch (e) {
      alert(e.message);
    }
  };
};

export const handleUpdateCoupon = (coupon, addToast) => {
  return async dispatch => {
    try {
      await authApiClient(API_METHOD.PATCH, `stamp/${coupon.id}`, coupon);
      addToast();
      dispatch(handleLoadCoupon(coupon.id));
    } catch (e) {
      alert(e.message);
    }
  };
};

export const handleUpdateCouponStatus = (state, coupons) => async dispatch => {
  try {
    dispatch(setSubmitting(true));
    await authApiClient(API_METHOD.PATCH, `stamp/${state.id}`, state);
    dispatch(updateCouponStatus(state, coupons));
  } catch (e) {
    alert(e.message);
  } finally {
    dispatch(setSubmitting(false));
  }
};

export const handleDeleteCoupon = couponId => {
  return async dispatch => {
    try {
      await authApiClient(API_METHOD.DELETE, `stamp/${couponId}`, undefined);
      dispatch(handleLoadCoupons());
    } catch (e) {
      alert(e.message);
    }
  };
};

export const handleIssueRandomCode = (random, addToast) => {
  return async dispatch => {
    try {
      await authApiClient(API_METHOD.POST, 'coupon/random', random);
      addToast();
      dispatch(handleLoadCoupons());
    } catch (e) {
      alert(e.message);
    }
  };
};

// 초기 상태 선언
const initialState = [];

// 리듀서 선언
export default function coupon(state = initialState, action) {
  switch (action.type) {
    case LOAD_COUPON: {
      for (let key in action.coupon) {
        if (action.coupon[key] !== 0 && !action.coupon[key]) action.coupon[key] = '';
        if (typeof action.coupon[key] == 'number') action.coupon[key] = String(action.coupon[key]);
      }

      if (action.coupon.condition_obj == '{}' || !action.coupon.condition_obj)
        action.coupon.condition_obj = couponValues.condition_obj;
      else action.coupon.condition_obj = JSON.parse(action.coupon.condition_obj);

      if (action.coupon.exclude_condition_obj == '{}' || !action.coupon.exclude_condition_obj)
        action.coupon.exclude_condition_obj = couponValues.exclude_condition_obj;
      else action.coupon.exclude_condition_obj = JSON.parse(action.coupon.exclude_condition_obj);

      if (action.coupon.start_time) {
        action.coupon.start_time = moment(action.coupon.start_time).format('YYYY-MM-DDTHH:mm');
      }

      if (action.coupon.expire_time) {
        action.coupon.expire_time = moment(action.coupon.expire_time).format('YYYY-MM-DDTHH:mm');
      }

      if (!action.coupon.available_expire_time || !action.coupon.duration_days) {
        action.coupon.expiration_action = 'issued';
      }

      if (action.coupon.available_expire_time && action.coupon.duration_days === '0') {
        action.coupon.duration_days = '';
        action.coupon.expiration_action = 'date';
      } else if (action.coupon.duration_days) {
        action.coupon.expiration_action = 'issued';
      }

      if (action.coupon.available_expire_time) {
        action.coupon.available_expire_time = moment(action.coupon.available_expire_time).format('YYYY-MM-DDTHH:mm');
        action.coupon.expiration_action = 'date';
      }

      if (action.coupon.price === '0') action.coupon.price = '';

      if (action.coupon.percent === '0') action.coupon.percent = '';

      if (!action.coupon.has_notification) {
        action.coupon.has_notification = false;
      }

      if (!action.coupon.is_unsubscribe_on_discount_end) {
        action.coupon.is_unsubscribe_on_discount_end = false;
      }

      if (action.coupon.notification_buffers?.length) {
        action.coupon.notification_buffers = action.coupon.notification_buffers.map(item => ({
          value: item,
          label: item,
        }));
      }

      // 할인 적용 범위 카테고리 설정
      // 할인 대상 범위가 1(전체)인 경우 해당 카테고리 활성화
      if (action.coupon.condition_obj.all === 1) {
        action.coupon.condition_obj.all = '1';
        action.coupon.discount_condition_range = DISCOUNT_CONDITION_RANGES.ALL;
      }

      if (action.coupon.condition_obj.min_price) {
        action.coupon.condition_obj.min_price = action.coupon.condition_obj.min_price.toString();
      }

      if (action.coupon.condition_obj.max_discount_price) {
        action.coupon.condition_obj.max_discount_price = action.coupon.condition_obj.max_discount_price.toString();
      }

      // 할인 카테고리 항목이 존재하는 경우, 할인 대상 범위 버튼을 활성화
      if (
        action.coupon.condition_obj.sool_category_type ||
        action.coupon.condition_obj.product_category_id ||
        action.coupon.condition_obj.direct_purchase ||
        action.coupon.condition_obj.merchandise_category_id
      ) {
        action.coupon.discount_condition_range = DISCOUNT_CONDITION_RANGES.CATEGORY;
      }

      // 어떤 ID Array가 존재하느냐에 따라 할인 대상 범위의 카테고리 변경
      // 할인 대상 ID는 String으로 변경하여 입력 (ex. [1,2,3] => "1,2,3")
      if (action.coupon.condition_obj.curation_display_id_arr) {
        action.coupon.discount_condition_range = DISCOUNT_CONDITION_RANGES.CURATION;
        action.coupon.discount_condition_id_list = action.coupon.condition_obj.curation_display_id_arr.toString();
      } else if (action.coupon.condition_obj.product_id_arr) {
        action.coupon.discount_condition_range = DISCOUNT_CONDITION_RANGES.PRODUCT_UNIT;
        action.coupon.discount_condition_id_list = action.coupon.condition_obj.product_id_arr.toString();
      } else if (action.coupon.condition_obj.product_option_id_arr) {
        action.coupon.discount_condition_range = DISCOUNT_CONDITION_RANGES.OPTION_UNIT;
        action.coupon.discount_condition_id_list = action.coupon.condition_obj.product_option_id_arr.toString();
      }

      // 할인 카테고리 항목 중 탁주, 약/청주, 과실주, 증류주인 경우 해당 카테고리 항목을 활성화
      const { sool_category_type } = action.coupon.condition_obj;
      ['takju', 'chungju', 'wine', 'soju'].forEach(soolType => {
        if (soolType === sool_category_type) {
          action.coupon.discount_category = soolType;
        }
      });

      // 할인 카테고리 항목 중 술 전체, 선물 전체, 기물 전체 경우 해당 카테고리 항목을 활성화
      const { product_category_id } = action.coupon.condition_obj;
      const map = { 1: 'sool', 2: 'present', 3: 'merchandise' };
      if (map[product_category_id]) {
        action.coupon.discount_category = map[product_category_id];
      }

      // 할인 카테고리 항목 중 담화마켓인 경우 해당 카테고리 항목을 활성화
      if (action.coupon.condition_obj.direct_purchase == '1') {
        action.coupon.discount_category = 'damhwa';
      }

      // 할인 카테고리 항목 중 잔인 경우 해당 카테고리 항목을 활성화
      if (action.coupon.condition_obj.merchandise_category_id == '1') {
        action.coupon.discount_category = 'glass';
      }

      // 할인 적용 제외 범위 설정
      // 어떤 ID Array가 존재하느냐에 따라 할인 제외 대상 범위의 카테고리 변경
      // 할인 제외 대상 ID는 String으로 변경하여 입력 (ex. [1,2,3] => "1,2,3")
      if (action.coupon.exclude_condition_obj.curation_display_id_arr) {
        action.coupon.exclude_condition_range = EXCLUDE_DISCOUNT_CONDITION_RANGES.CURATION;
        action.coupon.exclude_condition_id_list =
          action.coupon.exclude_condition_obj.curation_display_id_arr.toString();
      } else if (action.coupon.exclude_condition_obj.product_id_arr) {
        action.coupon.exclude_condition_range = EXCLUDE_DISCOUNT_CONDITION_RANGES.PRODUCT_UNIT;
        action.coupon.exclude_condition_id_list = action.coupon.exclude_condition_obj.product_id_arr.toString();
      } else if (action.coupon.exclude_condition_obj.product_option_id_arr) {
        action.coupon.exclude_condition_range = EXCLUDE_DISCOUNT_CONDITION_RANGES.OPTION_UNIT;
        action.coupon.exclude_condition_id_list = action.coupon.exclude_condition_obj.product_option_id_arr.toString();
      }

      // 할인 제외 카테고리 항목이 존재하는 경우, 할인 제외 대상 범위 버튼을 활성화
      if (
        action.coupon.exclude_condition_obj.sool_category_type ||
        action.coupon.exclude_condition_obj.product_category_id ||
        action.coupon.exclude_condition_obj.direct_purchase ||
        action.coupon.exclude_condition_obj.merchandise_category_id ||
        action.coupon.exclude_condition_obj.already_discounted
      ) {
        action.coupon.exclude_condition_range = EXCLUDE_DISCOUNT_CONDITION_RANGES.CATEGORY;
      }

      // 할인 제외 카테고리 항목 중 탁주, 약/청주, 과실주, 증류주인 경우 해당 카테고리 항목을 활성화
      const { sool_category_type: excluded_sool_category_type } = action.coupon.exclude_condition_obj;
      ['takju', 'chungju', 'wine', 'soju'].forEach(soolType => {
        if (soolType === excluded_sool_category_type) {
          action.coupon.exclude_category = soolType;
        }
      });

      // 할인 제외 카테고리 항목 중 술 전체, 선물 전체, 기물 전체 경우 해당 카테고리 항목을 활성화
      const { product_category_id: excluded_product_category_id } = action.coupon.exclude_condition_obj;
      const excluded_map = { 1: 'sool', 2: 'present', 3: 'merchandise' };
      if (excluded_map[excluded_product_category_id]) {
        action.coupon.exclude_category = map[excluded_product_category_id];
      }

      // 할인 제외 카테고리 항목 중 담화마켓인 경우 해당 카테고리 항목을 활성화
      if (action.coupon.exclude_condition_obj.direct_purchase == '1') {
        action.coupon.exclude_category = 'damhwa';
      }

      // 할인 제외 카테고리 항목 중 잔인 경우 해당 카테고리 항목을 활성화
      if (action.coupon.exclude_condition_obj.merchandise_category_id == '1') {
        action.coupon.exclude_category = 'glass';
      }

      // 할인 제외 대상 범위가 할인 중인 상품인 경우 해당 카테고리 항목 활성화
      if (action.coupon.exclude_condition_obj.already_discounted == '1') {
        action.coupon.exclude_condition_range = EXCLUDE_DISCOUNT_CONDITION_RANGES.DISCOUNTED_PRODUCT;
      }

      action.coupon.is_subscriber_only
        ? (action.coupon.is_subscriber_only = ['1'])
        : (action.coupon.is_subscriber_only = []);

      if (action.coupon.condition_obj.subscription_plan_id_arr) {
        action.coupon.condition_obj.subscription_plan_id_arr.includes(1)
          ? (action.coupon.basic_damhwabox = ['1'])
          : (action.coupon.basic_damhwabox = []);

        action.coupon.condition_obj.subscription_plan_id_arr.includes(2)
          ? (action.coupon.soju_damhwabox = ['1'])
          : (action.coupon.soju_damhwabox = []);

        action.coupon.condition_obj.subscription_plan_id_arr.includes(3)
          ? (action.coupon.yakchungju_damhwabox = ['1'])
          : (action.coupon.yakchungju_damhwabox = []);
      }

      return action.coupon;
    }
    default:
      return state;
  }
}
