import qs from 'qs';
import { SUBSCRIPTION_PRODUCTS } from 'src/views/subscription/constants';

export const getSubscriptionQuery = query => {
  if (query.subscription_plan_id === SUBSCRIPTION_PRODUCTS[0].value) {
    delete query.subscription_plan_id;
  }

  const queryString = qs.stringify(query);

  return queryString ? `&${queryString}` : queryString;
};
