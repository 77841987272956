import { setSubmitting } from 'src/redux/modules/webpage/loading';

import { API_METHOD, authApiClient } from '../../api';

export const LOAD_PRODUCT_INFO = 'LOAD_PRODUCT_INFO';
export const SAVE_PRODUCT_INFO = 'SAVE_PRODUCT_INFO';
export const UPDATE_PRODUCT_INFO = 'UPDATE_PRODUCT_INFO';

export const saveProductInfo = productInfo => ({
  type: SAVE_PRODUCT_INFO,
  productInfo,
});

export const loadProductInfo = productInfo => ({
  type: LOAD_PRODUCT_INFO,
  productInfo,
});

export const updateProductInfo = productInfo => ({
  type: UPDATE_PRODUCT_INFO,
  productInfo,
});

export const handleSaveProductInfo = (productInfo, addToast) => async () => {
  try {
    await authApiClient(API_METHOD.POST, 'product/info', productInfo);
    addToast();
  } catch (e) {
    alert(e.message);
  }
};

export const handleUpdateProductInfo = (productInfo, addToast) => async dispatch => {
  try {
    dispatch(setSubmitting(true));
    await authApiClient(API_METHOD.PATCH, `product/info/${productInfo.id}`, productInfo);

    addToast();
  } catch (e) {
    alert(e.message);
  } finally {
    dispatch(setSubmitting(false));
  }
};

export const handleLoadProductInfo = productInfoId => async dispatch => {
  try {
    const response = await authApiClient(API_METHOD.GET, `product/info/${productInfoId}`, undefined);
    dispatch(loadProductInfo(response.data.result));
  } catch (e) {
    alert(e.message);
  }
};

const productInfo = (state = {}, action) => {
  switch (action.type) {
    case LOAD_PRODUCT_INFO: {
      return action.productInfo;
    }
    default:
      return state;
  }
};

export default productInfo;
