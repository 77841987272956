import qs from 'qs';

import { API_METHOD, authApiClient } from '../../api';

// 액션 타입
const LOAD_REVIEWS = 'reviews/LOAD_REVIEWS';
const RESET_REVIEWS = 'reviews/RESET_REVIEWS';

// 액션 생성함수
const loadReviews = reviews => ({ type: LOAD_REVIEWS, reviews });
const resetReviews = reviews => ({
  type: RESET_REVIEWS,
  reviews,
});

export const handleLoadReviews = (query, limit) => {
  return async dispatch => {
    try {
      const response = await authApiClient(API_METHOD.GET, `review?limit=${limit}&${qs.stringify(query)}`, undefined);
      dispatch(loadReviews(response.data.result));
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

export const handleResetReviews = reviews => {
  return dispatch => {
    dispatch(resetReviews(reviews));
  };
};

// 초기 상태 선언
const initialState = [];

// 리듀서 선언
export default function reviews(state = initialState, action) {
  switch (action.type) {
    case LOAD_REVIEWS: {
      action.reviews.rows = action.reviews.rows.map(item => ({
        ...item,
        name: (item.user && item.user.name && item.user.name) || '',
        phone: (item.user && item.user.phone && item.user.phone) || '',
        product_category_id:
          (item.product && item.product.product_category_id && item.product.product_category_id) || '',
      }));
      return action.reviews;
    }
    case RESET_REVIEWS: {
      return action.reviews;
    }
    default:
      return state;
  }
}
