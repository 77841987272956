import { API_METHOD, authApiClient } from '../../api';

// 액션 타입
export const LOAD_REVIEW = 'review/LOAD_REVIEW';
const RESET_REVIEW = 'review/RESET_REVIEW';

// 액션 생성함수
export const loadReview = review => ({ type: LOAD_REVIEW, review });
export const resetReview = () => ({
  type: RESET_REVIEW,
  review: {},
});

export const handleLoadReview = reviewId => {
  return async dispatch => {
    try {
      const response = await authApiClient(API_METHOD.GET, `review/${reviewId}`, undefined);
      dispatch(loadReview(response.data.result));
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

export const handleResetReview = () => {
  return dispatch => {
    dispatch(resetReview());
  };
};

export const handleReviewHideReasonSave = (reviewId, hideReason, toastMessageCallback) => {
  const payload = {
    type: 'add',
    hide_reason: hideReason,
  };

  return async dispatch => {
    try {
      await authApiClient(API_METHOD.PATCH, `review/hide/${reviewId}`, payload);
      dispatch(handleLoadReview(reviewId));
      toastMessageCallback();
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

export const handleReviewHideCancel = (reviewId, toastMessageCallback) => {
  const payload = {
    type: 'cancel',
    hide_reason: 'park1002genius', // 필요없는 값이지만 서버에서 보내달라고 해서 보냄
  };

  return async dispatch => {
    try {
      await authApiClient(API_METHOD.PATCH, `review/hide/${reviewId}`, payload);
      dispatch(handleLoadReview(reviewId));
      toastMessageCallback();
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

export const handleReviewReplySave = (reviewId, adminReply, toastMessageCallback) => {
  const payload = {
    type: 'add',
    admin_reply: adminReply,
  };

  return async dispatch => {
    try {
      await authApiClient(API_METHOD.PATCH, `review/admin-reply/${reviewId}`, payload);
      dispatch(handleLoadReview(reviewId));
      toastMessageCallback();
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

export const handleReviewReplyCancel = (reviewId, toastMessageCallback) => {
  const payload = {
    type: 'cancel',
    admin_reply: 'park1002genius', // 필요없는 값이지만 서버에서 보내달라고 해서 보냄
  };

  return async dispatch => {
    try {
      await authApiClient(API_METHOD.PATCH, `review/admin-reply/${reviewId}`, payload);
      dispatch(handleLoadReview(reviewId));
      toastMessageCallback();
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

// 초기 상태 선언
const initialState = [];

// 리듀서 선언
export default function review(state = initialState, action) {
  switch (action.type) {
    case LOAD_REVIEW: {
      return action.review;
    }
    case RESET_REVIEW: {
      return action.review;
    }
    default:
      return state;
  }
}
