export const asyncSelectType = {
  curation: 'curation',
  event: 'event',
  bannerStore: 'banner-store',
  bannerSubscribe: 'banner-subscribe',
  curationList: 'list-curation',
  eventList: 'list-event',
};

export const INPUT_MODE = {
  READ: 'read',
  MODIFY: 'moodify',
};

export const INPUT_MODE_KOR = {
  SUBMIT: '등록',
  MODIFY: '수정',
};

export const DISCOUNT_TYPE = {
  PERCENT: 'percent',
  PRICE: 'price',
};

export const SWEET_RELATED_SOOL_CATEGORY_ID = ['1', '2', '3', '4', '5', '6', '8', '11', '12', '13', '15', '16'];
export const ACIDITY_RELATED_SOOL_CATEGORY_ID = ['1', '2', '3', '4', '5', '6', '11', '12', '13', '15', '16'];
export const BITTER_RELATED_SOOL_CATEGORY_ID = ['1', '2', '3', '4', '5', '11', '12', '16'];
export const BODY_RELATED_SOOL_CATEGORY_ID = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
];
export const CARBONIC_RELATED_SOOL_CATEGORY_ID = ['1', '2', '6', '11', '13', '15'];
export const SCENT_STRENGTH_RELATED_SOOL_CATEGORY_ID = ['8', '9', '10', '14'];
export const AFTERTASTE_RELATED_SOOL_CATEGORY_ID = ['8', '9', '10', '14'];
export const TANNIN_RELATED_SOOL_CATEGORY_ID = ['6', '13', '15'];
export const FRUITY_RELATED_SOOL_CATEGORY_ID = ['16'];
export const SCENT_RELATED_SOOL_CATEGORY_ID = ['8', '9', '10', '14'];

export const CANCELED_SUBSCRIPTION_ALERT = '이미 구독 해지 상태라서 구독 해지가 불가능합니다';
export const SUBSCRIPTION_CANCEL_CONFIRM = id => {
  return `아이디가 ${id}인 구독을 취소하시겠습니까?`;
};
export const SUBSCRIPTION_SKIP_ALERT = '구독 중일 때만 쉬어가기 할 수 있습니다';
export const REQUEST_ADDRESS_SAVING = '저장되지 않은 배송지가 있습니다.';
export const SUBSCRIBED_ADDRESS_CHANGE_CONFIRM = id => {
  return `아이디가 ${id}인 구독 상태의 배송지를 변경하시겠습니까?`;
};
export const DELETE_ADDRESS_CONFIRM = '배송지를 삭제하시겠습니까?';
export const SUBSCRIBED_ADDRESSES_CHANGE_CONFIRM = addresses => {
  return `배송지를 변경하시겠습니까?
  이 배송지로 구독 중인 ${addresses} 담화박스의 정보가 모두 변경됩니다.`;
};

export const STATUS_CANCELED = 'canceled';
export const STATUS_SUBSCRIBING = 'subscribing';
export const STATUS_SKIPPED = 'skipped';
export const STATUS_SUCCESS = 'success';

// 권한 관리 시작
export const AUTH_TYPES = {
  NO_AUTH: 0,
  READ_ONLY: 1,
  ALLOW_ALL: 2,
};

export const DOMAINS = {
  DASHBOARD: 'dashboard',
  ADMIN: 'admin',
  PRODUCT: 'product',
  MAKER: 'maker',
  ORDER: 'order',
  SETTLEMENT: 'settlement',
  USER: 'user',
  SUBSCRIPTION: 'subscription',
  COUPON: 'coupon',
  WEB_PAGE: 'web_page',
  REVIEW: 'review',
  PUSH: 'push',
};
// 권한 관리 끝

export const BANNER_EXPOSURE_ORDER_COUNT = 20;
export const CURATION_EXPOSURE_ORDER_COUNT = 10;

export const EXCEL_DOWNLOAD_MAX = 1000000;

// 배송은 번개배송과 양조장배송으로 구분되며, 번개배송은 새벽도착과 오늘출발로 구분됩니다.
export const DELIVERY_TYPE = {
  DAWN_ARRIVE: {
    NAME: '새벽도착',
    ID: 226,
  },
  TODAY_START: {
    NAME: '오늘출발',
    ID: 138,
  },
  MAKER_DELIVERY: {
    NAME: '양조장 배송',
    ID: null, // 226, 138을 제외한 모든 delivery_policy_id이기 때문에 null
  },
};

export const LIGHTNING_DELIVERY_NAME = '번개배송';

export const TEAMFRESH_ADMIN_ID = 49;

export const NON_DELIVERY_ACTION_MAP = {
  POINT: {
    TITLE: '포인트 환불',
    DESC: '미출고 상품가의 5% 추가 보상 적립',
  },
  PAY_METHOD: {
    TITLE: '결제 수단으로 환불',
    DESC: '',
  },
  ALL_PRODUCTS: {
    TITLE: '전체 상품 함께 수령',
    DESC: '',
  },
};
