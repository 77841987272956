import qs from 'qs';

import { API_METHOD, authApiClient } from '../../api';

// 액션 타입
const LOAD_POINT_HISTORY = 'users/LOAD_POINT_HISTORY';

// 액션 생성함수
export const loadPointHistory = pointHistory => ({ type: LOAD_POINT_HISTORY, pointHistory });

export const handleLoadPointHistory = query => {
  return async dispatch => {
    try {
      const response = await authApiClient(API_METHOD.GET, `user/point?limit=10&${qs.stringify(query)}`, undefined);
      dispatch(loadPointHistory(response.data.result));
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

// 초기 상태 선언
const initialState = [];

// 리듀서 선언
export default function pointHistory(state = initialState, action) {
  switch (action.type) {
    case LOAD_POINT_HISTORY: {
      action.pointHistory.rows = action.pointHistory.rows.map(item => ({
        ...item,
        name: (item.user && item.user.name && item.user.name) || '',
        phone: (item.user && item.user.phone && item.user.phone) || '',
        email: (item.user && item.user.email && item.user.email) || '',
      }));
      return action.pointHistory;
    }
    default:
      return state;
  }
}
