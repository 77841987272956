import qs from 'qs';
import { initialMakerPolicyValues } from 'src/views/products/FormValues';

import { API_METHOD, authApiClient } from '../../api';

export const SET_MAKER_POLICY_FORM = 'SET_MAKER_POLICY_FORM';

export const LOAD_MAKER_POLICY = 'LOAD_MAKER_POLICY';
export const SAVE_MAKER_POLICY = 'SAVE_MAKER_POLICY';
export const UPDATE_MAKER_POLICY = 'UPDATE_MAKER_POLICY';

export const LOAD_MAKER_POLICIES = 'LOAD_MAKER_POLICIES';

export const loadMakerPolicy = makerPolicy => ({
  type: LOAD_MAKER_POLICY,
  makerPolicy,
});

export const loadMakerPolicies = makerPolicies => ({
  type: LOAD_MAKER_POLICIES,
  makerPolicies,
});

export const setMakerPolicyForm = makerPolicyForm => ({
  type: SET_MAKER_POLICY_FORM,
  makerPolicyForm,
});

export const handleLoadMakerPolicy = makerPolicyId => async dispatch => {
  try {
    const response = await authApiClient(API_METHOD.GET, `delivery-policy/maker/${makerPolicyId}?type=list`, undefined);
    dispatch(loadMakerPolicy(response.data.result));
  } catch (e) {
    alert(e.message);
  }
};

export const handleUpdateMakerPolicy = (makerPolicyForm, addToast) => async dispatch => {
  try {
    await authApiClient(API_METHOD.PATCH, `delivery-policy/${makerPolicyForm.id}`, makerPolicyForm);
    dispatch(handleLoadMakerPolicy(makerPolicyForm.maker_id));
    addToast();
  } catch (e) {
    alert(e.message);
  }
};

export const handleSaveMakerPolicy = (makerPolicyForm, addToast) => async dispatch => {
  try {
    await authApiClient(API_METHOD.POST, 'delivery-policy', makerPolicyForm);
    dispatch(handleLoadMakerPolicy(makerPolicyForm.maker_id));
    addToast();
  } catch (e) {
    alert(e.message);
  }
};

export const handleLoadMakerPolicies = query => async dispatch => {
  try {
    const response = await authApiClient(
      API_METHOD.GET,
      `delivery-policy?directPurchase=0&${qs.stringify(query)}`,
      undefined
    );
    dispatch(loadMakerPolicies(response.data.result));
  } catch (e) {
    alert(e.message);
  }
};

const initialState = {
  makerPolicy: [],
  makerPolicies: [],
  makerPolicyForm: initialMakerPolicyValues,
};

const makerPolicy = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MAKER_POLICY: {
      return {
        ...state,
        makerPolicy: action.makerPolicy,
      };
    }
    case LOAD_MAKER_POLICIES: {
      return {
        ...state,
        makerPolicies: action.makerPolicies,
      };
    }
    case SET_MAKER_POLICY_FORM: {
      action.makerPolicyForm.regular_delivery_price = String(action.makerPolicyForm.regular_delivery_price);
      action.makerPolicyForm.additional_delivery_price = String(action.makerPolicyForm.additional_delivery_price);
      action.makerPolicyForm.min_total_for_free_delivery = String(action.makerPolicyForm.min_total_for_free_delivery);
      action.makerPolicyForm.separate_delivery =
        action.makerPolicyForm.separate_delivery === true ||
        (Array.isArray(action.makerPolicyForm.separate_delivery) &&
          action.makerPolicyForm.separate_delivery.includes('1'))
          ? ['1']
          : [];

      return {
        ...state,
        makerPolicyForm: action.makerPolicyForm,
      };
    }
    default:
      return state;
  }
};

export default makerPolicy;
