export const SET_PREVIOUS_URL = 'SET_PREVIOUS_URL';

export const setPreviousUrl = url => ({
  type: SET_PREVIOUS_URL,
  previousUrl: url,
});

export const handleSetPreviousUrl = url => {
  return dispatch => {
    dispatch(setPreviousUrl(url));
  };
};

const url = (state = {}, action) => {
  switch (action.type) {
    case SET_PREVIOUS_URL:
      return action.previousUrl;
    default:
      return state;
  }
};

export default url;
