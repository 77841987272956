import qs from 'qs';

import { API_METHOD, authApiClient } from '../../api';

// 액션 타입
const LOAD_SCENTS = 'scents/LOAD_SCENTS';

// 액션 생성함수
export const loadScents = scents => ({ type: LOAD_SCENTS, scents });

export const handleLoadScents = query => {
  return async dispatch => {
    try {
      const response = await authApiClient(API_METHOD.GET, `scent?${qs.stringify(query)}`, undefined);
      dispatch(loadScents(response.data.result));
    } catch (e) {
      alert(e.message);
    }
  };
};
// 초기 상태 선언
const initialState = [];

// 리듀서 선언
export default function scents(state = initialState, action) {
  switch (action.type) {
    case LOAD_SCENTS:
      return action.scents;
    default:
      return state;
  }
}
