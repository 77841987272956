import { getSubscriptionQuery } from 'src/redux/modules/subscription/common';

import { API_METHOD, authApiClient } from '../../api';

// 액션 타입
export const LOAD_SUBSCRIPTION_HINTS = 'subscriptionSchedule/LOAD_SUBSCRIPTION_HINTS';

// 액션 생성함수
export const loadSubscriptionHints = subscriptionHints => ({ type: LOAD_SUBSCRIPTION_HINTS, subscriptionHints });

export const handleLoadSubscriptionHints = query => {
  return async dispatch => {
    try {
      const response = await authApiClient(
        API_METHOD.GET,
        `subscriptionHint?limit=10${query === undefined ? '' : getSubscriptionQuery(query)}`,
        undefined
      );
      dispatch(loadSubscriptionHints(response.data.result));
    } catch (e) {
      alert(e.message);
      return;
    }
  };
};

// 초기 상태 선언
const initialState = [];

// 리듀서 선언
export default function subscriptionHints(state = initialState, action) {
  switch (action.type) {
    case LOAD_SUBSCRIPTION_HINTS: {
      action.subscriptionHints = action.subscriptionHints.map(item => ({
        ...item,
        sool_name: item.sool.sool_name,
        name: item.subscription_schedule.name,
      }));
      return action.subscriptionHints;
    }
    default: {
      return state;
    }
  }
}
