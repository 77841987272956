export const SUBSCRIPTION_PRODUCTS = [
  { value: '0', option: '전체' },
  { value: '1', option: '종합 담화박스' },
  { value: '2', option: '증류주 담화박스' },
  { value: '3', option: '약청주 담화박스' },
];

export const SUBSCRIPTION_COMPLETE_QUERY_KEY = {
  USER_PHONE: 'user_phone',
  USER_NAME: 'user_name',
  USER_EMAIL: 'user_email',
  MERCHANT_ID: 'merchant_id',
  ADDRESS: 'address',
  SEARCH_TYPE: 'search_type',
  SEARCH_VALUE: 'search_value',
  REPAYMENT: 'repayment',
  COUPON_NAME: 'coupon_name',
};
